// REACT
import React, {useEffect, useState} from 'react';
// MUI
import {Grid, Paper, Skeleton, Typography} from '@mui/material';
// ASSETS
import {ReactComponent as HealthchecksIcon} from "@assets/svg/apps/healthcheck.svg";
import {Domain, GroupWork, QrCode2Outlined, RememberMeOutlined} from "@mui/icons-material";
// API
import {APIPost} from "@api";
// Types
import {SubscriptionItem} from "@interfaces";
// TRANSLATION
import {useTranslation} from 'react-i18next';
// Components
import {ServiceBillingList} from "./ServiceBillingList";
// Context
import {useUser} from "@context";
// Utils
import {returnUsedCurrency} from "@functions";
import moment from "moment";
import {standaloneSubscription} from "@/constants/plans_en";
// Hooks
import {usePermissions} from "@/Context/User/PermissionsProvider";
// Components
import {UnsubscribeFromModuleDialog} from "@/Views/Account/components/BillingAndPlans/overview/dialogs";

const {REACT_APP_API_URL} = process.env;

export default function BillingPerServiceTab(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    const {handleSuccess, handleError} = props
    const {user, credits, creditsDetails, billingInvoice} = useUser()
    const {isAccountTier, trueAccountType} = usePermissions();

    const styles = {
        iconList: {
            height: '25px',
            fontSize: 25,
            fill: '#525252',
        },
        skeleton: { borderRadius: 1 },
        synthesisPaper: { bgcolor: "#f6f8fa", p: 2, width: '100%' },
        serviceWrapper: { width: '100%' },
        subscriptionPaper: { p: 2, width: '100%', my: 2 },
        trialPaper: { p: 2, width: '100%', mb: 2, backgroundColor: "#c0e5bc" },
        subtitle: { marginTop: '16px' }

    };

    // Global states
    const [currency, setCurrency] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    // Specific credits
    const [creditsQRCodes, setCreditsQRCodes] = useState<any>(null);
    // subscription
    const [subscriptionInterval, setSubscriptionInterval] = useState<string>('');
    const [subscriptionType, setSubscriptionType] = useState<string>('');
    const [subscriptionPrice, setSubscriptionPrice] = useState<number>(0);
    // Services
    const [services, setServices] = React.useState<SubscriptionItem[]>([]);
    const [tailoredApps, setTailoredApps] = React.useState<any[]>([]);
    // Total
    const [totalDue, setTotalDue] = React.useState<number>(0);
    // Unsubscribe dialog
    const [openUnsubscribeDialog, setOpenUnsubscribeDialog] = React.useState<any>(null);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!creditsDetails) initSubscription(creditsDetails)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creditsDetails])

    useEffect(() => {
        if (!!credits) {
            handleQRCodesCredits(credits)
            createTailoredAppsList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credits])

    useEffect(() => {
        setTotalDue(0);
        if (!!subscriptionPrice) calculateTotalDue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services, subscriptionPrice])

    // Subscription and credits management
    const createServicesList = (_services: any) => {
        let usedServices: SubscriptionItem[] = [];
        for (const item of _services) {
            if ((!!item.credit.left && item.credit.left > 0) || (!!item.credit.bought && item.credit.bought > 0)) {

                let icon
                // check if subscription item has a sub_id in /last_invoice
                let invoiceObject
                let invoiceId = ""

                switch (item.type) {
                    case "healthchecks":
                        icon = <HealthchecksIcon style={styles.iconList}/>;
                        break;
                    case "extra_users_gold":
                        icon = <GroupWork style={styles.iconList}/>;
                        break;
                    case "extra_users_platinum":
                        icon = <GroupWork style={styles.iconList}/>;
                        invoiceObject = billingInvoice.items.find((it: any) => it.type === "extra users Platinum")
                        invoiceId = invoiceObject?.sub_id
                        break;
                    case "extra_vcards":
                        icon = <RememberMeOutlined style={styles.iconList}/>;
                        invoiceObject = billingInvoice.items.find((it: any) => it.type === "vcards")
                        invoiceId = invoiceObject?.sub_id
                        break;
                    case "domains":
                        icon = <Domain style={styles.iconList}/>;
                        break;
                    default:
                        icon = <QrCode2Outlined style={styles.iconList}/>;
                        break;
                }

                // add a credits path
                // defaults to this while everything can be bought from /alacarte
                let creditsPath = {
                    pathname: "/alacarte",
                    state: {
                        fromPlan: isAccountTier(["free"]) ? standaloneSubscription : trueAccountType,
                        topUp: !isAccountTier(["free"])
                    }
                }

                let newItem: SubscriptionItem = {...item, icon: icon, invoice_id: invoiceId, credits_path: creditsPath};
                usedServices.push(newItem);
            }
        }
        setServices(usedServices);
    }

    // "Tailored apps" refers to any app that does not have a fixed pricing, unsubscribe options and service flexibility
    // Here: Winelabel, Packaging, GS1 (eventually Chefcloud when we'll know what the fk it is)
    const createTailoredAppsList = () => {

        let apps: any[] = []

        for (const app of credits) {
            if (app.credits_bought > 0) {
                if (app.service_name === "packaging") {
                    apps.push({type: 'packaging', icon: <QrCode2Outlined style={styles.iconList}/>})
                } else if (app.service_name === "packaging_gs1") {
                    apps.push({type: 'packaging_gs1', icon: <QrCode2Outlined style={styles.iconList}/>})
                } else if (app.service_name === "winelabel") {
                    apps.push({type: 'winelabel', icon: <QrCode2Outlined style={styles.iconList}/>})
                }
            }
        }

        setTailoredApps(apps)
    }

    const initSubscription = (sub: any) => {

        setCurrency(creditsDetails.currency)

        if (sub.subscription.interval !== "" && sub.subscription.interval !== null) {
            setSubscriptionInterval(sub.subscription.interval);
        }

        setSubscriptionType(sub.subscription.type);
        setSubscriptionPrice(sub.subscription.price);

        if (!!sub.subscription_items) createServicesList(sub.subscription_items)
        setLoading(false);
    }

    const calculateTotalDue = () => {
        if (!!services && services.length > 0) {
            let servicesPrice = services.reduce((acc: any, service: any) => acc + service.price, 0);
            let total = subscriptionPrice + servicesPrice
            let totalRounded = Math.floor(total * 100) / 100
            let due = totalRounded.toFixed(2)
            setTotalDue(due as unknown as number);
        } else {
            setTotalDue(subscriptionPrice)
        }
    }

    // Handle specific information and hydrate credits
    const handleQRCodesCredits = (credits: any) => {
        for (const item of credits) {
            switch (item.service_name) {
                case "qrcodes":
                    setCreditsQRCodes({
                        bought: item.credits_bought,
                        left: item.credits_left,
                        used: item.credits_used
                    });
                    break;
            }
        }
    }

    const handleOpenModuleUnsubscribeDialog = (subscriptionId: string) => {
        setOpenUnsubscribeDialog(subscriptionId)
    }

    // Unsubscribe from module
    const handleModuleUnsubscribe = () => {
        if (typeof openUnsubscribeDialog !== "string") {
            handleError("critical error")
            return
        }
        setLoading(true);
        APIPost<any>(REACT_APP_API_URL + "/billing/unsubscribe/" + openUnsubscribeDialog, null).then((data) => {
            if (!data.ok) {
                handleError(t("error_unsubscribe_from_module"))
                return
            }

            handleSuccess(t("success_unsubscribe_from_module"))
            setLoading(false);
            setOpenUnsubscribeDialog(null)
            window.location.href = "/settings?billing";
        }).catch(() => handleError(t("error_unsubscribe_from_module")));
        setLoading(false);
    }


    // Display
    const renderSynthesis = () => {

        if (loading) return (
            <Grid item xs={12}>
                <Skeleton variant={"rectangular"} width={"100%"} height={179} sx={styles.skeleton}/>
            </Grid>
        )

        if (!!user && user.account_type === "free") return <></>

        return (
            <Grid item xs={12}>

                {
                    (!!creditsDetails && creditsDetails.is_trial) &&
                    <Paper variant={"outlined"} sx={styles.trialPaper}>
                        <Typography variant={"body2"} align={"left"} sx={{mt: "2px"}} color={"success.dark"}>
                            <b>{t("trial_still_ongoing_until")}{`${moment.unix(creditsDetails.day_trial_left).format("DD/MM/YYYY")}`}<br/></b>
                            {t("trial_no_charge")}
                        </Typography>
                    </Paper>
                }

                <Paper variant={"outlined"} sx={styles.synthesisPaper}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant={"body1"} align={"left"}>
                                <b>{t("Your_billing_is")}&nbsp;{t(`${subscriptionInterval}`).toLowerCase()}</b><br/>
                                {t("Subscription_type")}&nbsp;{t(`${subscriptionType}`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant={"body1"} align={"right"}>
                                <b>{returnUsedCurrency(totalDue, currency)}</b><br/>
                                {returnUsedCurrency(subscriptionPrice, currency)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <>
            <Grid container alignItems="center" alignContent="center" data-testid={"billingPerService"} spacing={2}>
                {/* Synthesis section */}
                {renderSynthesis()}
                {/*  Credits details  */}
                <ServiceBillingList {...{
                    handleOpenModuleUnsubscribeDialog,
                    creditsQRCodes,
                    currency,
                    subscriptionInterval, subscriptionType,
                    services, tailoredApps,
                    styles, t
                }} />
            </Grid>

            <UnsubscribeFromModuleDialog
                open={openUnsubscribeDialog}
                onClose={() => setOpenUnsubscribeDialog(null)}
                onUnsubscribe={handleModuleUnsubscribe}
                {...{t}}
            />
        </>
    );

}
