import React from "react";

export default function useIsIntersecting(ref: any) {

    const [isIntersecting, setIsIntersecting] = React.useState(false);

    const observer = React.useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting), { threshold: [1], rootMargin: "-64px 0px 0px 0px" }
            ),
        [],
    );

    React.useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}