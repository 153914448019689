import React, {useState, useEffect} from 'react';
// MUI
import {
    Box, Dialog, DialogActions,
    ButtonBase,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Typography, ListItem, ListItemText
} from "@mui/material";
import {Close} from "@mui/icons-material";
// Unitag UI
import {Button} from "@components";
// API
import {APIPut} from "@api";
// TRANSLATION
import {useTranslation} from "react-i18next";
// Styles
import {styles} from "./styles";

const {REACT_APP_API_URL} = process.env;

export default function UserRoleDialog(props: any) {

    const {open, onClose, onConfirm, user, organisation, handleError} = props;
    const {t} = useTranslation(['settings', 'common']);

    const [loading, setLoading] = useState<boolean>(false);

    // Role management
    const [role, setRole] = useState<string>("");

    useEffect(() => {
        if (user) setRole(user.user_role.toLowerCase());
    }, [user]);

    // Change user role
    const updateUserRole = (userID: string, role: string) => {
        setLoading(true);
        APIPut<any>(`${REACT_APP_API_URL}/org/${organisation?.organisation_id}/user`, {
            user_id: userID,
            role: role,
        }).then((data) => {
            if (data.status === 200 && data.parsedBody !== undefined) {
                setLoading(false);
                onConfirm();
            } else if (data.status !== 200) {
                setLoading(false);
                handleError(t("User_role_update_error_message"))
            }
        }).catch(() => {
            setLoading(false);
            handleError(t("User_role_update_error_message"))
        })
    }

    const handleClose = () => {
        if (loading) return null;
        onClose();
    }

    const userRoles = [
        {
            name: "admin",
            description: "Admin_role_description"
        },
        {
            name: "member",
            description: "Member_role_description"
        },
        {
            name: "analyst",
            description: "Analyst_role_description"
        }
    ]

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle sx={styles.dialogPadding}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            {t("Change_user_role")}
                        </Box>
                        <IconButton onClick={handleClose} aria-label={"close"}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={styles.dialogPadding}>

                    <Typography>
                        {t("Changing_user_role_text")}
                        <b>{user?.email_user} </b>
                        {t("Changing_user_role_within")}
                        <b>{organisation?.organisation_name}</b>.
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Box sx={styles.rolePaperWrapper}>
                        {userRoles.map((userRole: any, index: number) => {
                            return (
                                <div key={index}>
                                    <ButtonBase
                                        sx={styles.rolePaperButtonWrapper}
                                        onClick={() => setRole(userRole.name)}
                                    >
                                        <Paper
                                            variant={"outlined"}
                                            sx={role === userRole.name ? styles.selectedRolePaper : styles.rolePaper}
                                        >
                                            <ListItem>
                                                <ListItemText
                                                    primary={t(userRole.name)}
                                                    secondary={t(userRole.description)}
                                                />
                                            </ListItem>
                                        </Paper>
                                    </ButtonBase>
                                </div>
                            )
                        })}
                    </Box>
                </DialogContent>

                <DialogActions sx={styles.dialogPadding}>
                    <Button onClick={handleClose} text>
                        {t("common:Cancel")}
                    </Button>
                    <Button
                        primary
                        onClick={() => updateUserRole(user?.invite_uuid, role.toUpperCase()) }
                        disabled={loading}
                    >
                        {t("Change_user_role")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
