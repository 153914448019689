import React from "react"

import {Button, useMediaQuery} from "@mui/material";
import {Star} from "@mui/icons-material";
import {useStyles} from "@/Context/Ui/DashboardUiProvider";


const TrustpilotWidget = () => {

    const {theme} = useStyles()
    const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

    if (smallDevice) return <></>

    return (
        <>

                <Button
                    href={"https://uk.trustpilot.com/review/www.unitag.io"}
                    target={"_blank"}
                    rel={"noreferrer"}
                    variant={"outlined"}
                    sx={{
                        backgroundColor: "white",
                        borderRadius: 0,
                        color: "black",
                        mt: 1
                    }}
                >
                    Review us on  &nbsp;<Star color={"primary"} sx={{ height: 24, width: 24, pb: "2px"}} />&nbsp; Trustpilot
                </Button>
        </>
    )
}

export default TrustpilotWidget