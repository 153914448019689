import React, {useState, useEffect} from 'react';
// MUI
import {Paper, Typography, Box} from "@mui/material";
// styles
import {styles} from "../styles";

export default function MemberPanel(props: any) {

    const {organisationAsMember, t} = props
    const [organisationName, setOrganisationName] = useState<string>("");

    useEffect(() => {
        if (!!organisationAsMember) {
            setOrganisationName(organisationAsMember.organisation_name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationAsMember]);

    return (
        <Box sx={styles.blockBalance}>
            <Paper variant={"outlined"} sx={styles.paper}>
                <Typography>
                    {t("Member_of")}<b>{organisationName}</b>
                </Typography>
            </Paper>
        </Box>
    )
}
