import React from "react"
import {Link} from "react-router-dom";
// MUI
import {
    Divider,
    Drawer as MUIDrawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
// Icons (MUI & Custom)
import {Dashboard, Star, GroupWork, AddCircle, AccountCircle} from "@mui/icons-material"
import {ReactComponent as QRCodesIcon} from "@assets/img/new-icons/qrcode.svg"
import {ReactComponent as VcardsIcon} from "@assets/img/new-icons/vcard.svg"
import {ReactComponent as AnalyticsIcon} from "@assets/img/new-icons/analytics.svg"
import {ReactComponent as AtlasIcon} from "@assets/img/new-icons/atlas.svg"
import {ReactComponent as PackagingIcon} from "@assets/img/new-icons/packaging.svg"
import {ReactComponent as CampaignsIcon} from "@assets/img/new-icons/campaigns.svg"
import {ReactComponent as WinelabelIcon} from "@assets/img/new-icons/winelabel.svg"
import {useStyles} from "@/Context/Ui/DashboardUiProvider";
import {ReactComponent as AllAppsIcon} from "../../../assets/svg/apps/apps.svg";
// MUI mixins and custom interfaces
import {styled, Theme, CSSObject} from '@mui/material/styles';
import {
    freeSubscription,
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";
import {usePermissions} from "@/Context/User/PermissionsProvider";

const drawerWidth = 300

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    borderRadius: 0,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    borderRadius: 0,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MUIDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),

        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function NavbarDrawer(props: any) {

    const {
        open, setOpen,
        onClose,
        styles, t
    } = props

    const {isAccountRole, isAccountTier, hasAccess, trueAccountType} = usePermissions()

    const {theme} = useStyles()

    let iconProps = {
        fill: theme.palette.secondary.main,
        style: {
            height: 24, width: 24
        }
    }

    const drawerDividersStyles = {
        my: !open ? "20.5px" : "10px",
    }

    const returnLocationUpgradePlan = (accountType: any) => {
        if (!!accountType) {
            switch(accountType) {
                case freeSubscription: return { fromPlan: standaloneSubscription, topUp: false  };
                case standaloneSubscription: return { fromPlan: standaloneSubscription, topUp: true  };
                case standardSubscription: return { fromPlan: standardSubscription, topUp: true  };
                case goldSubscription: return { fromPlan: goldSubscription, topUp: true  };
                case platinumSubscription: return { fromPlan: platinumSubscription, topUp: true  };
                default: return { fromPlan: standaloneSubscription, topUp: false  };
            }
        }
    }

    return (
        <>
            <Drawer
                variant={"permanent"}
                open={open}
                onClose={() => setOpen(!open)}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                <DrawerHeader></DrawerHeader>
                <List>
                    <ListItemButton dense component={Link} to="/" onClick={onClose}>
                        <ListItemIcon><Dashboard style={{fill: '#626262'}}/></ListItemIcon>
                        <ListItemText primary={t("Home")}/>
                    </ListItemButton>

                    <ListItemButton dense component={Link} to="/settings?profile" onClick={onClose}>
                        <ListItemIcon><AccountCircle style={{fill: '#626262'}}/></ListItemIcon>
                        <ListItemText primary={t("Account")}/>
                    </ListItemButton>

                    {
                        (isAccountTier(["gold", "platinum"]) && isAccountRole(["admin", "owner"])) &&
                        <ListItemButton dense component={Link} to="/settings?organisations" onClick={onClose}>
                            <ListItemIcon><GroupWork style={{fill: '#626262'}}/></ListItemIcon>
                            <ListItemText primary={t("Organisations")}/>
                        </ListItemButton>
                    }

                    <Divider sx={drawerDividersStyles} textAlign={"left"}>
                        {open && <Typography variant={"subtitle2"}>{t("Applications")}</Typography>}
                    </Divider>

                    <ListItemButton dense component={Link} to="/applications" onClick={onClose}>
                        <ListItemIcon><AllAppsIcon style={{fill: '#626262'}}/></ListItemIcon>
                        <ListItemText primary={t("All_apps")}/>
                    </ListItemButton>

                    <Divider sx={styles.drawerStaticDivider}/>

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/analytics"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <AnalyticsIcon {...iconProps} />
                        </ListItemIcon>
                        <ListItemText primary="Analytics"/>
                    </ListItemButton>

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/atlas"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <AtlasIcon {...iconProps} />
                        </ListItemIcon>
                        <ListItemText primary="Atlas"/>
                    </ListItemButton>

                    {
                        isAccountTier(["gold", "platinum"]) &&
                        <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/campaigns"
                                        onClick={onClose}>
                            <ListItemIcon>
                                <CampaignsIcon {...iconProps} />
                            </ListItemIcon>
                            <ListItemText primary="Campaigns"/>
                        </ListItemButton>
                    }

                    {
                        hasAccess("packaging.access") &&
                        <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/packaging"
                                        onClick={onClose}>
                            <ListItemIcon>
                                <PackagingIcon {...iconProps} />
                            </ListItemIcon>
                            <ListItemText primary="Packaging"/>
                        </ListItemButton>
                    }

                    <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/qrcodes/list"
                                    onClick={onClose}>
                        <ListItemIcon>
                            <QRCodesIcon {...iconProps} />
                        </ListItemIcon>
                        <ListItemText primary="QR Codes"/>
                    </ListItemButton>

                    {
                        hasAccess("vcards.access") &&
                        <ListItemButton dense sx={styles.drawerAppButtons} component={Link} to="/applications/vcardpro"
                                        onClick={onClose}>
                            <ListItemIcon>
                                <VcardsIcon {...iconProps} />
                            </ListItemIcon>
                            <ListItemText primary="vCard Pro"/>
                        </ListItemButton>
                    }

                    {
                        hasAccess("winelabel.access") ? <ListItemButton dense sx={styles.drawerAppButtons} component={Link}
                                                    to="/applications/winelabel/control_panel" onClick={onClose}>
                                <ListItemIcon>
                                    <WinelabelIcon {...iconProps} />
                                </ListItemIcon>
                                <ListItemText primary={t('App_winelabel')}/>
                            </ListItemButton>
                            : null
                    }

                    {/* Drawer section 2 */}
                    {
                        (isAccountRole(["admin", "owner"]) || isAccountTier(["free", "standalone", "standard"])) &&
                        <>
                            <Divider sx={drawerDividersStyles} textAlign={"left"}>
                                {open && <Typography variant={"subtitle2"}>{t("Billing_Plans")}</Typography>}
                            </Divider>

                            <ListItemButton dense component={Link} to="/plans" onClick={onClose}>
                                <ListItemIcon><Star/></ListItemIcon>
                                <ListItemText primary={t("Upgrade")}/>
                            </ListItemButton>

                            <ListItemButton dense component={Link} to={{
                                pathname: "/alacarte",
                                state: returnLocationUpgradePlan(trueAccountType)
                            }} onClick={onClose}>
                                <ListItemIcon><AddCircle/></ListItemIcon>
                                <ListItemText primary={t("Credits_add")}/>
                            </ListItemButton>
                        </>
                    }
                </List>
            </Drawer>
        </>
    )
}
