import React from "react"
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {returnUsedCurrency} from "@functions";
import {Link} from "react-router-dom";

export default function ModuleBilling(props: any) {

    const {
        included, tailored,
        service,
        interval, currency,
        handleOpenModuleUnsubscribeDialog,
        t
    } = props

    const styles = {
        paper: { width: "100%" },
        gridContainer: { },
        header: { p: "16px 16px 0 16px" },
    }

    const renderModuleHeader = () => {
        return (
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} >
                    <Stack direction={"row"} sx={styles.header} alignItems={"center"} spacing={2}>
                        {service?.icon}
                        <Typography variant={"body2"} align={"left"} fontWeight={600}>
                            {t(service?.type)}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
            </Grid>
        )
    }

    const renderModulePricing = () => {
        if (!service) return <Skeleton />
        return (
            <Grid item xs={12}>
                {
                    included ?
                        <Typography variant={"body2"} align={"center"} color={"success.main"}>
                            {t("included_in_plan")}
                        </Typography>
                    : tailored ?
                        <Typography variant={"body2"} align={"center"} color={"success.main"}>
                            {t("tailored_billing")}
                        </Typography>
                    :
                        <Typography variant={"body2"} color={"secondary"} align={"center"}>
                            {interval === "yearly" ? t("service_yearly") : t("service_monthly")}&nbsp;
                            {returnUsedCurrency(service.price, currency)} ({returnUsedCurrency(service.price_per_unit, currency)} {t("per_unit")})
                        </Typography>
                }
            </Grid>
        )
    }

    const renderModuleUsage = () => {

        if (!service) return <Grid item xs={12} sx={{ textAlign: "center" }}><CircularProgress /></Grid>
        if (tailored) return <></>

        const returnCreditProgress = (credits: any) => {
            if (!!credits) {
                let {left, used} = credits;
                const totalCredits = used + left;
                return Math.round((used / totalCredits) * 100);
            }
            return 0;
        }

        return (
            <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant={"body2"} align={"center"}>
                        {
                            service.type === "qrcodes" ?
                                t("usage") + " " + service.credit?.used + (service.credit?.left > 9999 ? " " : "/" + service.credit?.left)
                            :
                                t("usage") + " " + service.credit?.used + "/" + service.credit?.bought
                        }
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <LinearProgress
                        variant="determinate"
                        value={returnCreditProgress(service.credit)}
                        sx={{
                            mx: 3,
                            mt: 2,
                            borderRadius: '5px',
                            height: '10px',
                            backgroundColor: "rgba(0,0,0,0.15)"
                        }}/>
                </Grid>
            </Grid>
        )
    }

    const renderModuleActions = () => {
        if (included || tailored) return <Box sx={{ height: "52.5px", width: "100%" }}></Box>
        return (
            <Grid item xs={12} container>
                <Grid item xs={12} md={6}>
                    <Button
                        color={"secondary"}
                        startIcon={<Add />}
                        disabled={!service.credits_path}
                        component={Link}
                        to={!!service.credits_path ? service.credits_path : "#"}
                        fullWidth
                    >
                        {t("buy_more")}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        color={"error"}
                        startIcon={<Delete />}
                        disabled={!service.invoice_id}
                        fullWidth
                        onClick={() => handleOpenModuleUnsubscribeDialog(service.invoice_id)}
                    >
                        {t("remove_module")}
                    </Button>
                </Grid>

            </Grid>
        )
    }

    return (
        <>
            <Paper variant={"outlined"} sx={styles.paper}>
                <Grid container spacing={2} sx={styles.gridContainer}>
                    {renderModuleHeader()}
                    {renderModulePricing()}
                    {renderModuleUsage()}
                    {renderModuleActions()}
                </Grid>
            </Paper>
        </>
    )
}