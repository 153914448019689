import React from "react"
// MUI
import {
    Chip,
    Collapse,
    Divider, List,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Menu,
} from "@mui/material";
import {Code, ExpandLess, ExpandMore} from "@mui/icons-material";
// Context
import {useUser} from "@context";
import {APIPost} from "@api";
import {usePermissions} from "@/Context/User/PermissionsProvider";

const {REACT_APP_API_URL} = process.env;

export default function DevMenu(props: any) {

    const { REACT_APP_ENV } = props

    const {
        getUser, user,
        getComplexUser, complexUser,
        getCredits, credits,
        getCreditsDetails, creditsDetails,
        getBillingMethod, billingMethod,
        getBillingInvoice, billingInvoice,
        getOrganisation, organisation,
        organisationInvitations,
        getOrganisationAsMember, organisationAsMember
    } = useUser()

    const {
        accessPermissions, usagePermissions
    } = usePermissions()

    const styles = {
        chip: { mr: 2 },
        menu: { mt: 3 },
        menuPaper: { maxHeight: 350 },
        divider: { my: 1 },
        listSubheader: { lineHeight: 2 },
        nestedList: { "& div": { py: 0 }, "& span": { pl: 2 } }
    }

    const [anchorDev, setAnchorDev] = React.useState<null | HTMLElement>(null);
    const [openFetch, setOpenFetch] = React.useState<boolean>(false)

    if (REACT_APP_ENV ==="production") { return null }

    // Control
    const handleOpenMenu = (event: any) => {
        if (REACT_APP_ENV === "production") return null
        setAnchorDev(event.currentTarget)
    }

    const handleCloseMenu = () => { setAnchorDev(null); setOpenFetch(false) }

    const displayHydrationError = () => {
        console.log("%cError: " +
            "This data set is not hydrated yet. " +
            "Refresh it manually or try this command on a page that requires this data's hydration.",
            "color: red")
    }

    // Misc functions
    const clearLocalStorage = () => {
        handleCloseMenu();
        localStorage.removeItem("terms_alert");
        localStorage.removeItem("cookies_alert");
    }
    // Hydration
    const fetchAll = () => {
        getUser()
        getComplexUser()
        getCredits()
        getCreditsDetails()
        getBillingInvoice()
        getBillingMethod()
        getOrganisation()
        getOrganisationAsMember()
    }

    // Logging
    const logPostLoginUser = () => { handleCloseMenu(); (!!user) ? console.log(user) : displayHydrationError() }

    const logComplexUser = () => { handleCloseMenu(); (!!complexUser) ? console.log(complexUser) : displayHydrationError() }

    const logCredits = () => { handleCloseMenu(); (!!credits) ? console.log(credits) : displayHydrationError() }

    const logCreditsDetails = () => { handleCloseMenu(); (!!creditsDetails) ? console.log(creditsDetails) : displayHydrationError() }

    const logLastInvoice = () => { handleCloseMenu(); (!!billingInvoice) ? console.log(billingInvoice) : displayHydrationError() }

    const logBillingMethod = () => { handleCloseMenu(); (!!billingMethod) ? console.log(billingMethod) : displayHydrationError() }

    const logOrganisation = () => { handleCloseMenu(); (!!organisation) ? console.log(organisation) : displayHydrationError() }

    const logInvitations = () => { handleCloseMenu(); (!!organisationInvitations) ? console.log(organisationInvitations) : displayHydrationError() }

    const logOrganisationAsMember = () => { handleCloseMenu(); (!!organisationAsMember) ? console.log(organisationAsMember) : displayHydrationError() }

    const killFlexAccount = () => {
        // only works for remi+testflex@unitag.io
        // /billing/unsubscribe-instant
        APIPost(REACT_APP_API_URL + "/billing/unsubscribe-instant", {}).then((res) => {
            if (res.status === 200) console.log("account killed")
        }).finally(() => {
            setTimeout(() => { window.location.reload() }, 5000)
        })
    }

    return (
        <>
            {
                (REACT_APP_ENV === "sandbox" || REACT_APP_ENV === "local") &&
                    <>
                        <Chip
                            sx={styles.chip}
                            icon={<Code sx={{ color: "#ffffff"}} />}
                            size="small"
                            label={REACT_APP_ENV === "sandbox" ? "ENV: Sandbox" : REACT_APP_ENV === "local" ? "ENV: Local" : "-"}
                            color={REACT_APP_ENV === "sandbox" ? "secondary" : REACT_APP_ENV === "local" ? "error" : "error"}
                            clickable
                            onClick={handleOpenMenu}
                        />

                        <Menu
                            id="dev-menu"
                            anchorEl={anchorDev}
                            keepMounted
                            open={Boolean(anchorDev)}
                            onClose={handleCloseMenu}
                            sx={styles.menu}
                            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                            transformOrigin={{vertical: "top", horizontal: "center"}}
                            MenuListProps={{ dense: true }}
                            slotProps={{ paper: { sx: styles.menuPaper } }}
                        >
                            <ListSubheader sx={styles.listSubheader}>Permissions</ListSubheader>
                                <ListItemButton onClick={() => console.log(accessPermissions)}>
                                    <ListItemText primary={"Access permissions"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => console.log(usagePermissions)}>
                                    <ListItemText primary={"Usage permissions"} />
                                </ListItemButton>

                            <Divider sx={styles.divider} />

                            <ListSubheader sx={styles.listSubheader}>Logging data</ListSubheader>
                                <ListItemButton onClick={logPostLoginUser}>
                                    <ListItemText primary={"User (post login)"} />
                                </ListItemButton>
                                <ListItemButton onClick={logComplexUser}>
                                    <ListItemText primary={"User (complex)"} />
                                </ListItemButton>
                                <ListItemButton onClick={logCredits}>
                                    <ListItemText primary={"Credits"} />
                                </ListItemButton>
                                <ListItemButton onClick={logCreditsDetails}>
                                    <ListItemText primary={"Credits details"} />
                                </ListItemButton>
                                <ListItemButton onClick={logBillingMethod}>
                                    <ListItemText primary={"Billing method"} />
                                </ListItemButton>
                                <ListItemButton onClick={logLastInvoice}>
                                    <ListItemText primary={"Last billing invoice"} />
                                </ListItemButton>
                                <ListItemButton onClick={logOrganisation}>
                                    <ListItemText primary={"Organisation"} />
                                </ListItemButton>
                                <ListItemButton onClick={logInvitations}>
                                    <ListItemText
                                        primary={"Organisation Invitations"}
                                        secondary={"Requires parameters"}
                                        secondaryTypographyProps={{
                                            variant: "caption",
                                            fontStyle: "italic"
                                        }}
                                    />
                                </ListItemButton>
                                <ListItemButton onClick={logOrganisationAsMember}>
                                    <ListItemText primary={"Organisation as member"} />
                                </ListItemButton>

                            <Divider sx={styles.divider} />

                            <ListSubheader sx={styles.listSubheader}>Fetching data</ListSubheader>
                                <ListItemButton onClick={fetchAll}>
                                    <ListItemText primary={"Fetch all"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => setOpenFetch(!openFetch)}>
                                    <ListItemText primary={"Fetch by data type"} />
                                    {openFetch ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>

                                <Collapse in={openFetch}>
                                    <List disablePadding component={"div"} sx={styles.nestedList} dense>
                                        <ListItemButton onClick={() => getUser()}>
                                            <ListItemText primary={"User (postLogin)"} />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => getComplexUser()}>
                                            <ListItemText primary={"User (complex)"} />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => getCredits()}>
                                            <ListItemText primary={"Credits"} />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => getCreditsDetails()}>
                                            <ListItemText primary={"Credits details"} />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => getBillingMethod()}>
                                            <ListItemText primary={"Billing method"} />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => getBillingInvoice()}>
                                            <ListItemText primary={"Last billing invoice"} />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => getOrganisation()}>
                                            <ListItemText primary={"Organisation"} />
                                        </ListItemButton>
                                        <ListItemButton onClick={() => getOrganisationAsMember()}>
                                            <ListItemText primary={"Organisation as member"} />
                                        </ListItemButton>
                                    </List>
                                </Collapse>

                            <Divider sx={styles.divider} />

                            <ListSubheader sx={styles.listSubheader}>Limited</ListSubheader>
                            <ListItemButton onClick={() => killFlexAccount()}>
                                <ListItemText
                                    primary={"Account killswitch"}
                                    secondary={"Account restricted"}
                                    secondaryTypographyProps={{
                                        variant: "caption",
                                        fontStyle: "italic"
                                    }}
                                />
                            </ListItemButton>

                            <Divider sx={styles.divider} />

                            <ListSubheader sx={styles.listSubheader}>Miscellaneous</ListSubheader>
                                <ListItemButton onClick={clearLocalStorage}>
                                    <ListItemText primary={"Clear local storage (all)"} />
                                </ListItemButton>
                                <ListItemButton
                                    component={"a"}
                                    href={"https://github.com/UnitagEngineering/dashboard/tree/dev#readme"}
                                    target={"_blank"}
                                >
                                    <ListItemText
                                        primary={"Developer's handbook"}
                                        secondary={"Restricted"}
                                        secondaryTypographyProps={{
                                            variant: "caption",
                                            fontStyle: "italic"
                                        }}
                                    />
                                </ListItemButton>
                        </Menu>
                    </>

            }
        </>
    )
}