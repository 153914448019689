import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {Container, Typography, Divider, Grid, Paper, ButtonBase, Box} from '@mui/material';
// TRANSLATION
import {useTranslation} from "react-i18next";
// COMPONENTS
import {AppHeader} from "@components";
import {useUser} from "@context";
// icons
import {ReactComponent as QRCodesIcon} from "@assets/img/new-icons/qrcode.svg"
import {ReactComponent as VcardsIcon} from "@assets/img/new-icons/vcard.svg"
import {ReactComponent as AnalyticsIcon} from "@assets/img/new-icons/analytics.svg"
import {ReactComponent as AtlasIcon} from "@assets/img/new-icons/atlas.svg"
import {ReactComponent as PackagingIcon} from "@assets/img/new-icons/packaging.svg"
import {ReactComponent as CampaignsIcon} from "@assets/img/new-icons/campaigns.svg"
import {ReactComponent as WinelabelIcon} from "@assets/img/new-icons/winelabel.svg"
import {useStyles} from "@/Context/Ui/DashboardUiProvider";
import {usePermissions} from "@/Context/User/PermissionsProvider";

// For mapping purposes
// name: Add lowercase name (will be used as a literal translation from commonLNG.json)
// icon: Add direct import as done upwards
// link (optional): Add a custom link ONLY IF route differs from "/applications/{name}"
// The secondary text is directly translated from commonLNG.json following the key "App_list_{name}_intro"

// { name: "vcards", icon: VCardsIcon, link: "/applications/vcard/control_panel" },

export default function AppList() {

    const {theme} = useStyles()
    const {hasAccess} = usePermissions()

    let iconProps = {
        fill: theme.palette.secondary.main,
        style: {
            height: 50, width: 50
        }
    }



    const {t} = useTranslation(['common']);
    const {credits} = useUser()



    useEffect(() => {
        if (!!credits) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderList = () => {
        let apps = [
            { name: "analytics", icon: <AnalyticsIcon {...iconProps} /> },
            { name: "atlas", icon: <AtlasIcon {...iconProps} /> },
            { name: "campaigns", icon: <CampaignsIcon {...iconProps} /> },
            { name: "packaging", icon: <PackagingIcon {...iconProps} /> },
            { name: "qrcodes", icon: <QRCodesIcon {...iconProps} />, link: "/applications/qrcodes/list" },
            { name: "vcardpro", icon: <VcardsIcon {...iconProps} />, link: "/applications/vcardpro" },
        ]

        if (hasAccess("winelabel.access")) {
            apps.push({ name: "Wine Label", icon: <WinelabelIcon {...iconProps} />, link: "/applications/winelabel/control_panel"})
        }

        return (
            <>
                <Grid container spacing={4} justifyContent="center" direction="row">
                    {
                        !!apps && apps.map((app: any, index: number) => {
                            const {name, icon, link} = app;
                            return (
                                <Grid item xs={10} sm={4} md={3} sx={styles.gridItem} key={index}>
                                    <ButtonBase component={Link} sx={styles.buttonBase} to={link ? link : `/applications/${name}`}>
                                        <Paper variant="outlined" sx={styles.paper}>
                                            <Box>{icon}</Box>
                                            <Typography variant={"h6"} component="h4" sx={styles.appTitle}>{t(name)}</Typography>
                                            {app.name !== "Wine Label" && <Typography variant={"body2"} component="p">{t(`App_list_${name}_intro`)}</Typography>}
                                        </Paper>
                                    </ButtonBase>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </>
        )

    }

    const styles = {
        paper: {
            p: 3,
            width: "100%",
            height: "100%",
            alignItems: "center",
            transition: "0.2s ease-in-out",
            borderRadius: 5,
            "&:hover": {
                borderColor: 'secondary.main',
                backgroundColor: '#f8f8f8'
            },
            "&:hover svg": {
                fill: 'secondary.main'
            }
        },
        appTitle: { fontWeight: 700, my: 1 },
        gridItem: { textAlign: 'center' },
        buttonBase: { height: '100%', width: '100%' },
    };

    return (
        <Container maxWidth="xl" sx={{ py: 4, px: 2, minHeight: '65vh' }}>

            <Grid container justifyContent={"center"}>
                <Grid item xs={10}>
                    <AppHeader
                        paths={[{ name: "Unitag_console", path: "/" }]}
                        positionName={t("Applications")}
                        title={"Applications"}
                        subTitle={t("Applications_list_header")}
                    />

                    <Divider style={{margin: "0px 0px 32px 0px"}}/>

                    {renderList()}
                </Grid>
            </Grid>
        </Container>
    );
}
