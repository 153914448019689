import React, {useState} from "react"
import {Link} from "react-router-dom";
// MUI
import {
    Avatar, Box,
    Grid,
    Menu, MenuItem,
    Paper, Skeleton, Stack,
    Typography
} from "@mui/material";
import {AirplanemodeActiveSharp, ArrowDropDown, Receipt, RocketLaunch, Send} from "@mui/icons-material";
// Unitag UI
import {Button} from "@components";
// Static
import {
    freeSubscription,
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";
// API
import {usePermissions} from "@/Context/User/PermissionsProvider";
import moment from "moment/moment";


export default function BillingOverviewInformation(props: any) {

    const {
        creditsDetails, subscriptionInterval,
        totalDue,
        handleTabSwitch,
        currentInvoice,
        renewalDate,
        t
    } = props

    const {trueAccountType, hasOrganisation} = usePermissions()

    const styles = {
        planTypeWrapper: {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor: "#f6f8fa",
            borderRadius: '4px'
        },
        cancelConfirmationText: {
            color: 'rgb(226,120,33)',
            fontSize: '0.9rem',
            fontStyle: 'italic'
        },
        skeleton: { borderRadius: "8px" },

        accountTierPaper: {
            p: 2
        }
    }

    const [anchorInvoiceMenu, setAnchorInvoiceMenu] = useState<null | HTMLElement>(null);

    const returnCurrency = (amount: any) => {
        if (!!currentInvoice && !!currentInvoice.currency) {
            switch (currentInvoice.currency) {
                case "eur": return `${amount}€`
                case "gbp": return `£${amount}`
                default: return `$${amount}`
            }
        } else return `$${amount}`
    }

    const renderRenewalDate = () => {
        return (
            <>
                <Typography variant="body2">
                    {
                        renewalDate === "--" ?
                            <Skeleton />
                        :
                            <>
                                {t("Renewal_date")}
                                {renewalDate}
                            </>
                    }
                </Typography>
            </>
        );
    }

    // Display
    const renderInvoiceBlock = () => {

        // Do not display of free user
        if (trueAccountType === freeSubscription) return <></>

        const renderCurrentInvoice = () => {

            if (!!creditsDetails && !!creditsDetails.is_trial) {
                // User is in trial
                return (
                    <Typography variant="body2" sx={{ color: "success.main" }}>
                        {t("trial_still_ongoing_until")}{`${moment.unix(creditsDetails.day_trial_left).format("DD/MM/YYYY")}`}
                    </Typography>
                )
            } else return (
                <Grid container spacing={2} justifyContent={"space-between"}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="body2" component="h6">
                            {subscriptionInterval === "yearly" ? t("current_yearly_invoice") : t("current_monthly_invoice")}
                        </Typography>
                        <Typography variant="h6" component="p" style={{marginBottom: '16px'}}>
                            {returnCurrency(totalDue)}
                        </Typography>
                        {renderRenewalDate()}
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Paper variant={"outlined"} sx={{ p: 2, width: "100%", height: "100%", textAlign: "right" }}>
                            <Button secondary text onClick={() => handleTabSwitch(1)}>
                                {t("see_invoice_details")}
                            </Button>
                        </Paper>
                    </Grid>

                </Grid>
            )
        }

        return (
            <>
                <Paper variant="outlined">
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sx={{ display: trueAccountType === freeSubscription ? "none" : "inline-block" }}>
                            <Box sx={{ p: 1, backgroundColor: "#efefef" }}>
                                {renderInvoiceDownloader()}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ p: 2 }}>
                            {renderCurrentInvoice()}
                        </Grid>
                    </Grid>
                </Paper>

            </>
        )
    }

    const renderInvoiceDownloader = () => {

        const downloadLatestInvoice = (path: string) => {
            window.open(path, "_blank")?.focus();
            setAnchorInvoiceMenu(null);
        }

        if (currentInvoice.invoice_pdf !== "" && !!currentInvoice.invoice_pdf) {
            let pdf: string = currentInvoice.invoice_pdf;

            return (
                <>
                    <Button
                        secondary
                        text
                        startIcon={<Receipt />}
                        endIcon={<ArrowDropDown />}
                        onClick={(e: any) => setAnchorInvoiceMenu(e.currentTarget)}
                        disabled={!!creditsDetails?.is_trial}
                    >
                        {t("Download_past_invoice")}
                    </Button>

                    <Menu
                        open={Boolean(anchorInvoiceMenu)}
                        anchorEl={anchorInvoiceMenu}
                        onClose={() => setAnchorInvoiceMenu(null)}
                    >
                        <MenuItem onClick={() => downloadLatestInvoice(pdf)}>
                            {t("Download_latest_invoice")}
                        </MenuItem>
                        <MenuItem disabled>
                            {t("Download_all_invoice")}
                        </MenuItem>
                        <MenuItem disabled>
                            {t("Download_invoice_at_date")}
                        </MenuItem>
                    </Menu>
                </>
            )
        }
    }

    const renderAccountTier = () => {

        let planDetails: any

        switch (trueAccountType) {
            case freeSubscription:
                planDetails = {
                    label: "free",
                    subtitle: "Upgrade_paid_text",
                    icon: <RocketLaunch sx={{ color: "platinum.main" }} fontSize={"large"} />,
                    upgradePath: "/plans",
                }
                break;
            case standardSubscription:
                planDetails = {
                    label: "standard",
                    subtitle: "Upgrade_gold_text",
                    icon: <Send sx={{ color: "standard.main" }} fontSize={"large"} />,
                    upgradePath: "/plans",
                }
                break;
            case standaloneSubscription:
                planDetails = {
                    label: "standalone",
                    subtitle: "Upgrade_paid_text",
                    icon: <Send sx={{ color: "standalone.main" }} fontSize={"large"} />,
                    upgradePath: "/plans",
                }
                break;
            case goldSubscription:
                planDetails = {
                    label: "gold",
                    subtitle: "Upgrade_plat_text",
                    icon: <AirplanemodeActiveSharp sx={{ color: "gold.main" }} fontSize={"large"} />,
                    upgradePath: "/plans",
                }
                break;
            case platinumSubscription:
                planDetails = {
                    label: "platinum",
                    subtitle: "Upgrade_highest_text",
                    icon: <RocketLaunch sx={{ color: "platinum.main" }} fontSize={"large"} />,
                    upgradePath: "",
                }
                break;
            default:
                return <Skeleton width={"100%"} height={125} />
        }

        if (!planDetails) return <Skeleton width={"100%"} height={125} />

        return (
            <>
                <Paper variant={"outlined"} sx={{...styles.accountTierPaper, backgroundColor: planDetails.label + ".light"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={planDetails.upgradePath ? 9 : 12}>
                            <Stack direction={"row"} spacing={2}>
                                <Avatar sx={{ backgroundColor: "white", height: 55, width: 55}}>
                                    {planDetails.icon}
                                </Avatar>
                                <Box sx={{ pt: "4px"}}>
                                    <Typography fontWeight={600}>
                                        {t(planDetails.label)} {hasOrganisation && <>({t("Organisation")})</>}
                                    </Typography>
                                    <Typography variant={"body2"}>
                                        {t(planDetails.subtitle)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid
                            item xs={12} md={3}
                            sx={{
                                display: planDetails.upgradePath ? "inline-block" : "none",
                                textAlign: "right"
                            }}
                        >
                            <Button
                                component={Link} to={planDetails.upgradePath}
                                sx={{
                                    backgroundColor: planDetails.label === "free" ? "primary.main" : "white",
                                    color: planDetails.label === "free" ? "white" : planDetails.label + ".main"
                                }}
                            >
                                {t("common:Upgrade")}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        )
    }

    return (
        <>
            <Grid item xs={12}>
                {renderAccountTier()}
            </Grid>

            <Grid item xs={12}>
                {renderInvoiceBlock()}
            </Grid>
        </>
    )
}