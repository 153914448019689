import React from "react";
import {Link} from "react-router-dom";
// MUI
import {Box, Button, Grid, ListItem, ListItemIcon, ListItemText, Paper, Typography} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";
// Components
import {PlanContent, PlanPaper, WantMoreContent} from "@/Views/Plans/components/PlansDisplay";
// content
import {vcardsFeatures} from "@/Views/Plans/components/misc/plansAndFeaturesLibrary";


function StandaloneTab(props: any) {

    const {pinned, setTabValue, subscriptions, smallDevice, frequency, trueAccountType, currency, t} = props

    const renderPlansColumn = () => {
        return (
            <>
                <Grid item xs={14} sm={7} md={5} lg={3} sx={{ backgroundColor: "transparent" }}>
                    <PlanPaper {...{pinned, t}}>
                        <WantMoreContent onTab={() => setTabValue(0)} {...{pinned, t}} />
                    </PlanPaper>
                </Grid>
            </>
        )
    }

    const renderVCardPlans = () => {
        return (
            <>
                {renderPlansColumn()}

                {
                    subscriptions.standalone.map((plan: any, i: number) => {
                        return (
                            <>
                                <Grid item xs={14} sm={7} md={5} lg={3} sx={{ backgroundColor: "transparent"}}>
                                    <PlanPaper
                                        {...{smallDevice, t}}
                                        vCard={plan.label !== "Enterprise"}
                                        enterprise={plan.label === "Enterprise"}
                                        color={plan.label === "Enterprise" ? "enterprise.main" : i === 0 ? "standalone.main" : "standalone.dark"}
                                    >
                                        <PlanContent
                                            {...{smallDevice}}
                                            vCard={plan.label !== "Enterprise"}
                                            enterprise={plan.label === "Enterprise"}
                                            {...{plan, frequency, currency, trueAccountType, t}}
                                        />
                                    </PlanPaper>
                                </Grid>
                            </>
                        )
                    })
                }
            </>
        )
    }

    const renderVCardDetails = () => {

        return (
            <>
                <Grid item xs={14} md={15} lg={9} sx={{ mt: 4 }}>
                    <Paper variant={"outlined"}>
                        <Box sx={{ width: "100%", p: 2, mb: 2, backgroundColor: "#f5f5f5", borderRadius: "8px 8px 0 0", display: "flex", justifyContent: "space-between" }}>
                            <Typography fontWeight={600}>{t("vcards_details")}</Typography>
                            <Button
                                color={"standalone"}
                                variant={"contained"}
                                disableElevation
                                size={"small"}
                                component={Link}
                                to={subscriptions.standalone[0].link}
                                sx={{ color: "white"}}
                            >
                                {t("try_it_free")}
                            </Button>
                        </Box>

                        <Grid container justifyContent={"center"} rowSpacing={1} columnSpacing={1} sx={{ pb: 2 }}>
                            {
                                vcardsFeatures.keyFeatures.map((feat: any, i: number) => (
                                    <Grid item xs={12} md={6} key={"key" + i}>
                                        <ListItem disableGutters sx={{ pl: 3, minHeight: 30}}>
                                            <ListItemIcon sx={{minWidth: "40px"}}>
                                                <CheckCircle sx={{color: "standalone.main"}} />
                                            </ListItemIcon>
                                            <ListItemText sx={{fontSize: '16px'}} disableTypography>
                                                {t(feat)}
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={14} md={15} lg={9} sx={{ mt: 4 }}>
                    <Paper variant={"outlined"}>
                        <Box sx={{ width: "100%", p: 2, mb: 2, backgroundColor: "#f5f5f5", borderRadius: "8px 8px 0 0", display: "flex", justifyContent: "space-between" }}>
                            <Typography fontWeight={600}>{t("vcards_enterprise_features")}</Typography>
                            <Button
                                color={"enterprise"}
                                variant={"contained"}
                                disableElevation
                                size={"small"}
                                component={'a'}
                                href={"https://unitag.io/contact"}
                                target={"_blank"}
                            >
                                {t("customize_your_plan")}
                            </Button>
                        </Box>

                        <Grid container justifyContent={"flex-start"} rowSpacing={1} columnSpacing={1} sx={{ pb: 2 }}>
                            {
                                vcardsFeatures.enterpriseFeatures.map((feat: any, i: number) => (
                                    <Grid item xs={12} key={"key" + i}>
                                        <ListItem disableGutters sx={{ px: 3, minHeight: 30}}>
                                            <ListItemIcon sx={{minWidth: "40px"}}>
                                                <CheckCircle sx={{color: "enterprise.main"}} />
                                            </ListItemIcon>
                                            <ListItemText sx={{fontSize: '16px'}} disableTypography>
                                                {t(feat)}
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Paper>
                </Grid>
            </>
        )
    }

    return (
        <Box sx={{position: "sticky", top: "-1px", zIndex: "15!important"}}>
            <Box sx={{ top: "68px", position: "relative"  }}>
                <Grid container columns={15} spacing={1} justifyContent={"center"}>
                    {renderVCardPlans()}
                    <Grid item xs={15}></Grid>
                    {renderVCardDetails()}
                </Grid>
            </Box>
        </Box>
    )
}

export default StandaloneTab