import React from "react"
import {APIPost} from "@api";
import {Grid, Paper, Typography} from "@mui/material";
import {Button} from "@components";
import {useTranslation} from "react-i18next";

const {REACT_APP_API_URL} = process.env;

export default function HomeInvitationPopup(props: any) {

    const {pendingInvitation, handleError} = props;
    const {t} = useTranslation(['settings', 'common']);

    const styles = {
        paper: {
            p: 2,
            mt: 2,
            borderRadius: 5,
            backgroundColor: "#afc92825"
        }
    }

    const acceptPendingInvitation = () => {
        APIPost<object>(REACT_APP_API_URL + "/org/" + pendingInvitation?.organisation?.organisation_id + "/invite/" + pendingInvitation?.invitation_id + "/accept", {})
            .then(() => {
                window.location.reload();
            }).catch(() => handleError(t("Snackbar_invitation_accept_error")))
    }

    if (!pendingInvitation) return <></>

    return (
        <>
            <Paper variant={"outlined"} sx={styles.paper}>
                <Grid container spacing={1} alignItems="center" justifyContent={"space-between"}>
                    <Grid item xs={12} md={9}>
                        <Typography variant={"body2"}>
                            {t("Invitation_received_to")}<br/>
                            <span style={{ fontWeight: 700, fontSize: 21 }}>{pendingInvitation.organisation?.name}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} textAlign={"right"}>
                        <Button primary onClick={acceptPendingInvitation}>{t("Accept")}</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}