import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
// MUI
import {
    Container,
    CircularProgress,
    Divider,
    Grid,
    ListItemText,
    Paper,
    Typography,
    Box,
    Tabs, Tab
} from '@mui/material';
// Unitag UI
import {Button} from '@components';
// TRANSLATION
import {useTranslation} from "react-i18next";
// Views
import OrganisationsOverviewTab from "../components/OrganisationsAndUsers/tabs/OrganisationsOverviewTab";
import OrganisationsUsersTab from "../components/OrganisationsAndUsers/tabs/OrganisationsUsersTab";
import InvitationsPanel from "../components/OrganisationsAndUsers/panels/OrganisationsAsInvited";
import MemberPanel from "../components/OrganisationsAndUsers/panels/OrganisationsAsMember";
import CreateOrganisationPanel from "../components/OrganisationsAndUsers/panels/OrganisationsAsLegacyCreator";
import OrganisationsWinelabelTab from "@/Views/Account/components/OrganisationsAndUsers/tabs/OrganisationsWinelabelTab";
// Context
import {useUser} from "@context";
import {usePermissions} from "@/Context/User/PermissionsProvider";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`organisations-tabpanel-${index}`}
            aria-labelledby={`organisations-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ my: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `organisations-tab-${index}`,
        'aria-controls': `organisations-tabpanel-${index}`,
    };
}

export default function SettingsOrganisationsTab(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    // User context provider
    const {
        getCredits, credits,
        organisationAsMember,
        pendingInvitation,
    } = useUser()
    // Permissions and roles
    const {
        userRole,
        hasOrganisation,
        isAccountTier,
        hasAccess,
        loadingPermissions,
        trueAccountType
    } = usePermissions()
    // Error management
    const {handleSuccess, handleError} = props;

    // Tabs
    const [tabValue, setTabValue] = React.useState(0);
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };
    // Status
    const [userStatus, setUserStatus] = React.useState<string>("");
    const [allowWinelabel, setAllowWinelabel] = React.useState<boolean>(false)
    // The loading state goes to false as soon as userStatus gets defined
    const [loading, setLoading] = React.useState<boolean>(true);

    const styles = {
        tabs: {
            borderRadius: "4px 4px 0 0"
        },
        winelabelTab: {
            display: allowWinelabel ? "inline-flex" : "none"
        },
        docTab: {
            ml: 'auto'
        },
        padding: {
            p: 2
        },
        defaultPanelButton: {
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center"
        },
        loaderWrapper: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 5
        },
        header: {
            py: 1
        }
    }

    useEffect(() => {
        setAllowWinelabel(hasAccess("winelabel.access"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingPermissions])

    // Manage user status
    useEffect(() => {
        if (!!userRole) {
             if ((!hasOrganisation) && (isAccountTier(["gold", "platinum"]))) {
                // WARNING: The following chunk is here to manage "out of bounds" users that did not follow the frontend payment flow
                // This WILL cause bugs in the future unless managed in the backend!
                // Consider with great caution!
                setUserStatus("out of bounds");
                setLoading(false);
                return;
            } else if (hasOrganisation) {
                // User has an organisation role
                setUserStatus(userRole.toLowerCase())
                setLoading(false);
                return;
            }
        } else if (pendingInvitation) {
            setUserStatus("invited");
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole])

    const returnOrganisationAdministrationPanel = () => {
        return (
            <Box>
                <Box>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="organisation tabs">
                        <Tab label={t("Organisations")} sx={styles.tabs} {...a11yProps(0)} />
                        <Tab label={t("Users")} sx={styles.tabs} {...a11yProps(1)} disabled={isAccountTier(["standalone"])} />
                        <Tab label={t("Winelabel")} sx={{...styles.tabs, ...styles.winelabelTab}} {...a11yProps(2)} />

                        <Button
                            component={"a"}
                            href={"https://unitag.io/documentation/organisations/introduction"}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            spacing
                            dense
                            primary
                            sx={styles.docTab}
                        >
                            {t("common:Documentation")}
                        </Button>
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <OrganisationsOverviewTab {...{allowWinelabel, handleError, handleSuccess}} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <OrganisationsUsersTab  {...{handleError, handleSuccess}} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <OrganisationsWinelabelTab {...{credits, getCredits, t}} />
                </TabPanel>
            </Box>
        )
    }

    const returnInvitationsPanel = () => {
        if (!!pendingInvitation) {
            return <InvitationsPanel invitation={pendingInvitation} {...{handleError}} />
        }
        return loader();
    }

    const returnMemberPanel = () => {
        return <MemberPanel {...{organisationAsMember, t}} />
    }

    const returnCreateOrganisationPanel = () => {
        return <CreateOrganisationPanel {...{handleError, t}} />
    }

    const returnDefaultPanel = () => {
        return (
            <>
                <Paper variant={"outlined"} sx={styles.padding}>
                    <Grid container justifyContent={"space-between"} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <ListItemText
                                primary={t("Plan_display") + " " + t(trueAccountType)}
                                secondary={t("Orga_plan_needed")}
                            />
                        </Grid>
                        <Grid item xs={8} md={4} sx={styles.defaultPanelButton}>
                            <Button primary component={Link} to="/plans">
                                {t("Upgrade")}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        )
    }

    const loader = () => {
        return (
            <Box sx={styles.loaderWrapper}>
                <CircularProgress />
            </Box>
        )
    }

    const displayProperPanel = () => {

        if (loading || loadingPermissions) return loader()

        switch (userStatus) {
            case "invited": return returnInvitationsPanel();
            case "admin": return returnOrganisationAdministrationPanel();
            case "owner": return returnOrganisationAdministrationPanel();
            case "analyst":
            case "member": return returnMemberPanel();
            case "out of bounds": return returnCreateOrganisationPanel();
            default: return returnDefaultPanel();
        }
    }

    return (
        <main>
            <Container maxWidth="xl">

                <Grid container spacing={0} justifyContent='flex-start' alignItems={"baseline"}>
                    <Grid item xs={12} sx={{ flexGrow: 1, my: 2 }}>
                        <Typography variant='h5'>{t('Organizations_and_users')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        {displayProperPanel()}
                    </Grid>
                </Grid>


            </Container>
        </main>
    );
}