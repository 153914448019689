import React, {useEffect, useState} from 'react';
// Auth
import {Auth} from "aws-amplify";
// Translation & locale management
import i18next from "i18next";
import {Box, CircularProgress, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {standaloneSubscription} from "@/constants/plans_en";
import {useSnackbar} from "@hooks";
import {usePermissions} from "@/Context/User/PermissionsProvider";

const {REACT_APP_VCARDS_PRO_DOMAIN} = process.env;

export default function VCardProApp() {
    const {t} = useTranslation(['common']);
    const {handleError} = useSnackbar()
    const history = useHistory()
    const {hasAccess, hasOrganisation, loadingPermissions} = usePermissions()

    const [currentURL, setCurrentURL] = useState('');
    const [iframeHeight, setIframeHeight] = useState<any>(800);

    const [vcardCredit, setVcardCredit] = useState<boolean | undefined>(undefined);

    let _lng = i18next.language

    useEffect(() => {
        window.addEventListener("message", vcardsMessageHandler, false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!loadingPermissions) {
            if (hasAccess("vcards.access")) setVcardCredit(true)
            else setVcardCredit(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingPermissions]);

    useEffect(() => {
        if (vcardCredit) setIframeUrl()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vcardCredit]);

    useEffect(() => {
        setIframeUrl()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.language])

    const vcardsMessageHandler = (event: any) => {
        // we only want message from vcard pro
        if (event.origin !== REACT_APP_VCARDS_PRO_DOMAIN) return

        if (typeof event.data === 'string') {
            if (event.data === "refreshToken") {
                Auth.currentSession().then((sess) => {
                    event.source.postMessage(sess.getIdToken().getJwtToken(), REACT_APP_VCARDS_PRO_DOMAIN)
                }, () => {
                    handleError(t("error_get_identification"))
                }).catch(() => handleError(t("error_get_identification")))
            } else if (event.data.includes("resize")) {
                let size = JSON.parse(event.data)
                // remove intro message
                size.splice(0,1)
                setIframeHeight(size[0])
            } else if (event.data.includes("scrollTop")) {
                window.scrollTo(0, 0)
            }

        }
    }

    const setIframeUrl = () => {
        if (vcardCredit) {
            Auth.currentSession().then((sess) => {
                setIframeHeight(window.outerHeight);
                setCurrentURL(REACT_APP_VCARDS_PRO_DOMAIN + "?lng=" + _lng + "&token=" + sess.getIdToken().getJwtToken());
            }, () => {
                handleError(t("error_get_identification"))
            }).catch(() => handleError(t("error_get_identification")))
        }
    }

    const renderLoading = () => {
        return (
            <Container maxWidth={"lg"}>
                <Box sx={{margin: "auto", mt: 15, textAlign: "center"}}>
                    <CircularProgress/>
                    <br/>
                    <br/>
                    <Typography variant={"body2"} fontWeight={600}>
                        {t("common:loading")}
                    </Typography>
                </Box>
            </Container>
        )
    }

    if (vcardCredit === undefined || loadingPermissions) {
        return renderLoading()
    } else if (!hasAccess("vcards.access")) {
        history.push({ pathname: "/alacarte", state: { fromPlan: standaloneSubscription, topUp: true }  })
    } else if (!hasOrganisation) {
        // TODO: gracefully manage this
        return (<>error: no organisation</>)
    } else if (vcardCredit) {
        return (
            <iframe
                style={{width: '100%', border: "none"}}
                referrerPolicy="strict-origin-when-cross-origin"
                src={currentURL}
                title="Unitag VCards Pro"
                height={iframeHeight}
                allow="clipboard-write"
            />
        )
    }

    return renderLoading()
}
