// REACT
import React, {useEffect, useState, useRef} from 'react';
// TRANSLATION
import {useTranslation} from 'react-i18next';
// MUI
import {
    Box,
    Container,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Tooltip,
    Typography,
    IconButton, Skeleton,
} from '@mui/material';
// Unitag UI
import {Button} from '@components';
// PRIMEREACT
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// MUI ICONS
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// API
import {APIDelete, APIGet, APIPost} from "@api";
// IFACES
import {ApiKeys} from "@interfaces";
import SettingsUpgradeMessage from "@/Views/Account/components/SettingsUpgradeMessage";
import {useUser} from "@context";


const {REACT_APP_API_URL} = process.env;

function CreateApiKeyDialog(props: any) {

    const {open, onClose, t} = props

    const [label, setLabel] = useState('');

    return (
        <>
            <Dialog open={open} maxWidth="sm" style={{padding: '24px'}}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>{t('Api_key_new')}</Box>
                        <IconButton onClick={onClose}><CloseIcon/></IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent style={{overflow: 'hidden'}}>
                    <Grid container spacing={3} style={{marginBottom: '8px'}} justifyContent="space-between">
                        <Grid item md={12}>
                            <Typography>
                                {t('Api_key_new_label')}
                            </Typography>
                            <TextField
                                style={{minWidth: '235px'}}
                                id={"label"}
                                label={"label"}
                                variant="outlined"
                                onChange={(e: any) => setLabel(e.target.value)}
                                value={label}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{padding: '24px'}}>
                    <Button text onClick={onClose}>
                        {t("Cancel")}
                    </Button>
                    <Button primary onClick={() => props.onCreate(label)}>
                        {t("Create")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function DeleteApiKeyDialog(props: any) {

    const {open, onClose, onConfirm, t} = props

    return (
        <>
            <Dialog open={open} maxWidth="sm" style={{padding: '24px'}}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>{t('Api_key_deletion_headline')}</Box>
                        <IconButton onClick={onClose}><CloseIcon/></IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent style={{overflow: 'hidden'}}>
                    <Grid container spacing={3} style={{marginBottom: '8px'}} justifyContent="space-between">
                        <Grid item md={12}>
                            <Typography>
                                {t('Api_key_delete_confirmation')}
                            </Typography>
                            <p><strong>{props.apiLabel}</strong></p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{padding: '24px'}}>
                    <Button text onClick={onClose}>
                        {t("Cancel")}
                    </Button>
                    <Button primary onClick={onConfirm}>
                        {t('Api_key_delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default function SettingsApiKeysTab(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    const {handleSuccess, handleError} = props
    const {user, complexUser, getAuthorisations, authorisations} = useUser()

    // Loading
    const [loading, setLoading] = useState<boolean>(true)
    // Dialogs
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    // App Check
    const [isApiKeyAllowed, setIsApiKeyAllowed] = useState(false);
    // Key management
    const [apiKeys, setApiKeys] = useState<ApiKeys[] | undefined>(undefined);
    const [activeApiKey, setActiveApiKey] = useState({api_key_uuid: '', api_key_label: ''});
    const [showKey, setShowKey] = useState("");

    const dt = useRef(null);

    // check user has access getOrgHandler
    // apikeys disabled for free accounts
    useEffect(() => {
        getAuthorisations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!authorisations && authorisations.apiKeys) {
            setIsApiKeyAllowed(true);
            fetchApiKeys()
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorisations])

    const fetchApiKeys = () => {
        APIGet<ApiKeys[]>(REACT_APP_API_URL + "/developer/api_key").then((data) => {
            if (data.status === 200 && data.parsedBody !== undefined) {
                setApiKeys(data.parsedBody);
            } else {
                handleError(t('Api_key_error'))
            }
        }).catch(() => handleError(t('Api_key_error'))).finally(() => setLoading(false));
    }

    const createNewAPIKey = (label: string) => {
        let newApiKey: ApiKeys = {
            label: label,
            environment: window.location.host
        }

        APIPost<ApiKeys>(REACT_APP_API_URL + "/developer/api_key", newApiKey).then((data) => {
            if (data.status === 200 && data.parsedBody !== undefined) {
                const _newApiKey = apiKeys?.map(obj => ({...obj}));
                _newApiKey?.unshift(data.parsedBody);
                setApiKeys(_newApiKey);
                handleSuccess(t("Api_key_success"))
            } else {
                handleError(t('Api_key_error'))
            }
        }).catch(() => handleError(t('Api_key_error')));
    }

    const handleReveal = (uuid: string) => {
        if (showKey === uuid) setShowKey("")
        else setShowKey(uuid)
    }

    const handleDelete = (uuid: string) => {
        if (apiKeys !== undefined) {
            apiKeys.forEach(key => {
                if (key.api_key_uuid === uuid) {
                    setActiveApiKey({
                        api_key_uuid: key.api_key_uuid,
                        api_key_label: key.label!
                    });
                }
            });
        }
        setOpenDelete(true);
    }

    const deleteApiKey = () => {
        setOpenDelete(false);

        let todeleteApiKey: ApiKeys = {
            api_key_uuid: activeApiKey.api_key_uuid,
            environment: window.location.host
        };

        APIDelete<ApiKeys>(REACT_APP_API_URL + "/developer/api_key", todeleteApiKey).then((data) => {
            if (data.status === 200 && data.parsedBody !== undefined) {
                const _newApiKey = apiKeys?.map(obj => ({...obj}));

                if (_newApiKey !== undefined) {
                    _newApiKey.forEach((item, index) => {
                        if (item.api_key_uuid === activeApiKey.api_key_uuid) _newApiKey.splice(index, 1);
                    });

                    setApiKeys(_newApiKey);
                }
                handleSuccess(t("Api_key_success"))
            } else handleError(t('Api_key_error'))
        }).catch(() => handleError(t('Api_key_error')));
    }

    const handleDialogClose = () => {
        setOpenCreate(false);
        setOpenDelete(false);
        setActiveApiKey({api_key_label: '', api_key_uuid: ''});
    }

    const handleCreateFromDialog = (label: string) => {
        setOpenCreate(false);
        createNewAPIKey(label);
    }

    const actionsBodyTemplate = (rowData: any) => {
        return (
            <>
                <Tooltip
                    placement={"top"}
                    arrow
                    title={
                        showKey === rowData.api_key_uuid ? `${t("Api_keys_hide")}`
                        : `${t("Api_keys_display")}`
                    }
                >
                    <IconButton
                        value={rowData.api_key_uuid}
                        onClick={() => handleReveal(rowData.api_key_uuid)}
                        size="small"
                    >
                        {showKey === rowData.api_key_uuid ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                    </IconButton>
                </Tooltip>

                &nbsp;&nbsp;

                <Tooltip
                    placement={"top"}
                    arrow
                    title={`${t("Delete")}`}
                >
                    <IconButton
                        onClick={() => handleDelete(rowData.api_key_uuid)}
                        size="small"
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    const keyTemplate = (rowData: any) => {
        return (
            <TextField
                InputProps={{ readOnly: true }}
                style={{width: '100%', color: '#495057'}}
                type={showKey === rowData.api_key_uuid ? "text" : "password"}
                value={rowData.api_key}
            />
        );
    }

    const renderDataTable = () => {
        return (
            <Paper variant="outlined" sx={{padding: 0, width: '100%', borderRadius: 5, overflow: "hidden"}}>
                <DataTable
                    ref={dt}
                    dataKey="api_key_uuid"
                    value={apiKeys}
                    emptyMessage={"-"}
                >
                    <Column field="label" header={t("Api_keys_label")} />
                    <Column header={t("Api_key")} body={keyTemplate} />
                    <Column header={t("Actions")} body={actionsBodyTemplate} />
                </DataTable>
            </Paper>
        );
    }

    return (
        <main>
            <Container maxWidth="xl">
                <Grid container spacing={0} justifyContent="flex-start" alignItems="baseline">
                    <Grid item sx={{flexGrow: 1, my: 2}}>
                        <Typography variant="h5">
                            {t("Api_keys")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!isApiKeyAllowed}
                            primary small
                            onClick={() => setOpenCreate(true)}
                            startIcon={<AddCircleOutlineIcon/>}
                            sx={{ margin: "8px 0"}}
                        >
                            {t("Api_keys_create")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>

                    {
                        (!!complexUser && (complexUser.organisation_role === "MEMBER" || complexUser.organisation_role === "ANALYST")) ?
                            <Grid item xs={12}>
                                <SettingsUpgradeMessage
                                    unauthorized
                                    message={"members_cant_access"}
                                    {...{t}}
                                />
                            </Grid>
                        : !isApiKeyAllowed ?
                            <Grid item xs={12}>
                                <SettingsUpgradeMessage
                                    accountType={user.account_type}
                                    message={"API_plan_needed"}
                                    {...{t}}
                                />
                            </Grid>
                        :
                            <>
                                <Grid item xs={12} sx={{ my: 2 }}>
                                    <Typography variant="body2" style={{fontWeight: 300}}>
                                        {t("Api_keys_headline")}
                                    </Typography>
                                </Grid>

                                <br/>
                                <br/>

                                {
                                    loading ?
                                        <Grid item xs={12}>
                                            <Skeleton variant={"rectangular"} height={250} />
                                        </Grid>
                                    :
                                        <Grid item xs={12}>
                                            {renderDataTable()}
                                            <CreateApiKeyDialog
                                                open={openCreate}
                                                onClose={handleDialogClose}
                                                onCreate={handleCreateFromDialog}
                                                {...{t}}
                                            />
                                            <DeleteApiKeyDialog
                                                open={openDelete}
                                                apiLabel={activeApiKey.api_key_label}
                                                onClose={handleDialogClose}
                                                onConfirm={deleteApiKey}
                                                {...{t}}
                                            />
                                        </Grid>
                                }
                            </>
                    }
                </Grid>
            </Container>
        </main>
    );
}
