import React, {useEffect, useState, useRef} from 'react';
// MUI
import {
    Alert,
    Autocomplete,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
// MUI ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DoneIcon from "@mui/icons-material/Done";
import LockIcon from "@mui/icons-material/Lock";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileCopyIcon from "@mui/icons-material/FileCopy";
// API
import {APIGet, APIPost, APIPut} from "@api";
// Components
import {ConfigDomainDialog, CreateDomainDialog, ShareDomainDialog, DeleteDomainDialog} from "@/Views/Account/components/Domains";
// PRIMEREACT
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// IFACES
import {
    Domain,
    DomainConfiguration,
    GetOrganisationResponse,
    PostDomainResponse,
    PostLoginResponse,
    User
} from "@interfaces";
// TRANSLATION
import {useTranslation} from "react-i18next";
// IMGS
import YourDomain from "@assets/img/your_domain.png";
import UnitagDomain from "@assets/img/unitag_domain.png";
// Unitag UI
import {Button} from '@components';
import {useUser} from "@context";
import SettingsUpgradeMessage from "@/Views/Account/components/SettingsUpgradeMessage";

const {REACT_APP_API_URL} = process.env;

const useStyles = {
    content: {
        alignContent: 'center',
        alignItems: 'center',
        flexGrow: 1
    },
    paper: {
        padding: '16px'
    },
};

export default function SettingsDomainsTab(props: any) {

    const classes = useStyles;
    const {t} = useTranslation(['settings', 'common']);
    const {handleError, handleSuccess} = props
    const {
        user, complexUser,
        getAuthorisations, authorisations,
        getOrganisation, organisation,
        getOrganisationUsers, organisationUsers,
    } = useUser()
    // Loading
    const [loading, setLoading] = useState(true);

    // Dialogs
    const [openDomainsDialog, setOpenDomainsDalog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    // Get Domains + Table
    const [domainsList, setDomainsList] = useState<Domain[] | undefined>(undefined);
    const [selectedDomain, setSelectedDomain] = useState<string | null>(null);
    const [domainConfig, setDomainConfig] = useState<DomainConfiguration | null>(null);
    const [openConfigDialog, setOpenConfigDialog] = useState(false);
    // Organisation
    const [currentOrg, setCurrentOrg] = useState<GetOrganisationResponse | null>(null);
    // Sharing

    const [openSharingDialog, setOpenSharingDialog] = useState(false);
    const [currentDomainID, setCurrentDomainID] = useState('');
    const [currentDomainName, setCurrentDomainName] = useState('');
    // Autocomplete State For Organisations
    const [optionsUsers, setOptionsUsers] = useState<User[]>([]);

    // Stepper
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set<number>());
    const [skipped, setSkipped] = useState(new Set<number>());
    // Domains management
    const [requesting, setRequesting] = useState(false);
    const [requestedDomain, setRequestedDomain] = useState<string | null>(null);
    const [requestResponse, setRequestResponse] = useState<PostDomainResponse | null>(null);
    const [isDomainValid, setIsDomainValid] = useState(true);

    const [selectUsers, setSelectedUsers] = useState<User[] | null>(null);
    const [orgShared, setOrgShared] = useState(false);

    const [openUsers, setOpenUsers] = useState(false);

    const loadingUsers = openUsers && optionsUsers.length === 0;

    const dt = useRef(null);

    useEffect(() => {
        getAuthorisations()

        APIGet<Domain[]>(REACT_APP_API_URL + "/domains").then((data) => {
            if (data.status === 200) {
                if (data.parsedBody !== undefined) {
                    setDomainsList(data.parsedBody);
                }
            }
        }).catch(() => handleError(t("error_fetching_domains"))
        ).finally(() => {
            setLoading(false);
        })

        getOrganisation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!organisation) {
            if (organisation.member_org !== undefined && organisation.member_org !== null) {
                setCurrentOrg(organisation.member_org);
                return;
            }
            setCurrentOrg(organisation);
        }
    }, [organisation])

    useEffect(() => {
        if (selectedDomain === null || selectedDomain === undefined) {
            return undefined
        }

        APIGet<DomainConfiguration>(REACT_APP_API_URL + "/domains/" + selectedDomain).then((data) => {
            if (data.status === 200 && data.parsedBody !== undefined) {
                setDomainConfig(data.parsedBody);
            }
        }).catch(() => handleError(t("error_domains_configuration")))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDomain])

    useEffect(() => {
        if (!loadingUsers || !currentOrg) return
        getOrganisationUsers(currentOrg?.organisation_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers]);

    useEffect(() => {
        if (!!organisationUsers) setOptionsUsers(organisationUsers)
    }, [organisationUsers])

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            handleSuccess(t("success_copying_text"))
        }).catch(() => handleError(t("error_copying_text")))
    }

    // Main data management
    const refreshDomains = () => {
        APIGet<Domain[]>(REACT_APP_API_URL + "/domains").then((data) => {

            if (data.status === 200) {
                if (data.parsedBody !== undefined) {
                    setDomainsList(data.parsedBody);
                }
            }
        }).catch(() => handleError(t("error_fetching_domains")))
    }

    const deleteDomain = (event: any) => {
        setCurrentDomainID(event.currentTarget.value);

        // Loop through domains to find the current domain name
        for (const domain of domainsList!) {
            if (domain.domain_id === event.currentTarget.value) {
                setCurrentDomainName(domain.domain_name);
            }
        }

        setOpenDeleteDialog(true);
    }

    const checkCNAME = (event: any) => {

        const domainUUID = event.currentTarget.value;

        APIGet<any>(REACT_APP_API_URL + "/domains/" + domainUUID + "/check").then((data) => {
            if (data.status > 200) handleError(t("error_validating_domain"))
            else handleSuccess(t("success_validating_domain"))
        }).catch(() => handleError(t("error_validating_domain")))

    }

    // DATA TABLE FOR DOMAINS
    const handleSelectedRow = (uuid: string) => {
        setSelectedDomain(uuid);
    }

    const renderDataTable = () => {

        const statusDataTableColumn = (rowData: any) => {
            if (rowData.activated) {
                return <Chip size="small" icon={<DoneIcon/>} label={t("Activated")} style={{
                    backgroundColor: "rgb(175,201,40)",
                    color: "white",
                    textTransform: 'uppercase',
                    fontWeight: 500
                }}/>
            }

            switch (rowData.status) {
                case "PENDING_VERIFICATION":
                    return <Chip size="small" icon={<AccessTimeIcon/>} label={t("Pending_verification")} style={{
                        backgroundColor: '#C3C3C3',
                        color: "white",
                        textTransform: 'uppercase',
                        fontWeight: 500
                    }}/>
            }
        }

        const sslDataTableColumn = (rowData: any) => {

            if (rowData.ssl_activated) {
                return <Chip size="small" icon={<LockIcon/>} label={t("Secured")} style={{
                    backgroundColor: "rgb(175,201,40)",
                    color: "white",
                    textTransform: 'uppercase',
                    fontWeight: 500
                }}/>
            }
        }

        const sharingDataTableColumn = (rowData: Domain) => {

            if (currentOrg !== null && currentOrg.role === "MEMBER") {
                return null;
            }

            if (rowData.organisations !== undefined && rowData.users !== undefined) {
                return (
                    <div>
                        {rowData.organisations.map((o: any) => {
                            return (
                                <Chip
                                    size="small"
                                    label={o.name}
                                    color="secondary"
                                />
                            )
                        })}
                        {rowData.users.map((u: PostLoginResponse) => {
                            return (
                                <Chip
                                    size="small"
                                    label={u.email}
                                    color="secondary"
                                />
                            )
                        })}

                    </div>
                )
            } else if (rowData.organisations === undefined && rowData.users !== undefined) {
                return (
                    <div>
                        {rowData.users.map((u: PostLoginResponse) => {
                            return (
                                <Chip
                                    size="small"
                                    label={u.email}
                                    color="secondary"
                                />
                            )
                        })}
                    </div>
                )
            } else if (rowData.organisations !== undefined && rowData.users === undefined) {
                return (
                    <div>
                        {rowData.organisations.map((o: any) => {
                            return (
                                <Chip
                                    size="small"
                                    label={o.name}
                                    color="secondary"
                                />
                            )
                        })}
                    </div>
                )
            } else {
                return (
                    <Chip
                        size="small"
                        label="N/A"
                    />
                )
            }
        }

        const actionsDataTableColumn = (rowData: any) => {

            const canModify = rowData.can_modify;

            return (
                <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Tooltip placement={"top"} arrow title={`${t("See_config")}`}>
                            <IconButton
                                value={rowData.domain_id}
                                onClick={handleOpenConfigDialog}
                                size="small"
                                disabled={!canModify}
                            >
                                <VisibilityIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip placement={"top"} arrow title={`${t("Refresh_verification")}`}>
                            <IconButton
                                value={rowData.domain_id}
                                onClick={checkCNAME} size="small"
                                disabled={!canModify}
                            >
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip placement={"top"} arrow title={`${t("Sharing_opt")}`}>
                            <IconButton
                                value={rowData.domain_id}
                                onClick={handleOpenSharingDialog}
                                size="small"
                                disabled={!canModify}
                            >
                                <ShareIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            placement={"top"}
                            arrow
                            title={
                                !canModify ? `${t("Domain_delete_disabled")}`
                                    : `${t("Delete")}`
                            }
                        >
                            <IconButton
                                disabled={!canModify}
                                value={rowData.domain_id}
                                onClick={deleteDomain}
                                size="small"
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            )
        }

        return (
            <Paper variant={"outlined"} sx={{width: '100%', borderRadius: 0}}>
                <DataTable
                    ref={dt}
                    dataKey="domain_uuid"
                    value={domainsList}
                    style={{width: '100%',}}
                >
                    <Column field="domain_name" header={t("Domain_name")}/>
                    <Column header={t("Status")} body={statusDataTableColumn}/>
                    <Column header={t("SSL_activated")} body={sslDataTableColumn}/>
                    <Column header={t("Shared_with")} body={sharingDataTableColumn}/>
                    <Column header={t("Actions")} body={actionsDataTableColumn} style={{maxWidth: '200px'}}/>
                </DataTable>
            </Paper>
        )

    }

    // Dialogs and dialogs management
    const handleOpenSharingDialog = (event: any) => {
        const domainID = event.currentTarget.value;
        setCurrentDomainID(domainID);

        setOpenSharingDialog(true);
    }

    const handleCloseSharingDialog = () => {
        refreshDomains();
        setOpenSharingDialog(false);
    }

    const handleOpenConfigDialog = (event: any) => {
        handleSelectedRow(event.currentTarget.value);
        setOpenConfigDialog(true);
    }

    const handleOpenDomainsDialog = () => {
        setOpenDomainsDalog(true);
    }

    const handleCloseDomainsDialog = () => {
        // Refresh domains
        refreshDomains();
        setOpenDomainsDalog(false);
        handleReset()
    }

    const closeDeleteDialog = () => {
        setOpenDeleteDialog(false);
        refreshDomains();
    }

    const renderSharingDialog = () => {
        // Wow, this thing is weird
        let selectedUsers: PostLoginResponse[] | undefined;
        let is_shared_with_orgs: boolean = false;
        let domaine_name: string = "";

        if (domainsList !== undefined && domainsList !== null) {
            domainsList.some((d) => {
                if (d.domain_id === currentDomainID) {
                    selectedUsers = d.users;
                    is_shared_with_orgs = d.is_shared_with_orgs;
                    domaine_name = d.domain_name;
                    return true;
                }
                return false;
            })
        }

        return (
            <ShareDomainDialog
                domaine_name={domaine_name}
                is_shared_with_orgs={is_shared_with_orgs}
                domains={domainsList}
                open={openSharingDialog}
                org_id={currentOrg?.organisation_id}
                sub_orgs={currentOrg?.sub_organisations}
                domain_id={currentDomainID}
                onClose={handleCloseSharingDialog}
                selectedUsers={selectedUsers}
                {...{handleSuccess, handleError, t}}
            />
        )
    }

    // Domain creation stepper
    const steps = [
        `${t("Introduction")}`,
        `${t("Domain_names_request")}`,
        `${t("Domain_names_configure")}`,
        `${t("Domain_names_security")}`,
        `${t("Finish")}`
    ];

    const handleReset = () => {
        setActiveStep(0);
        setCompleted(new Set<number>());
        setSkipped(new Set<number>());
    };

    const completedSteps = () => {
        return completed.size;
    };

    const skippedSteps = () => {
        return skipped.size;
    };

    const allStepsCompleted = () => {
        return completedSteps() === 5 - skippedSteps();
    };

    const handleDomainNameChange = (event: any) => {
        setRequestedDomain(event.currentTarget.value);
    }

    const handleComplete = () => {

        // Check if is last step
        const isLastStep = () => {
            return activeStep === 4;
        };

        // Remaining steps count
        const remainingSteps = () => { return 5 - skippedSteps() }

        // Manage next step
        const handleNext = () => {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed
                      // find the first step that has been completed
                    steps.findIndex((_step, i) => !completed.has(i))
                    : activeStep + 1;

            setActiveStep(newActiveStep);
        };

        // Finish
        if (isLastStep()) handleCloseDomainsDialog()

        // Main switch
        switch (activeStep) {
            case 1:

                if (requestedDomain === null || requestedDomain === '') {
                    setIsDomainValid(false);
                    return;
                }

                setRequesting(true);

                APIPost<any>(REACT_APP_API_URL + "/domains", {
                    domain_name: requestedDomain
                }).then((data) => {
                    setRequesting(false);

                    if (!data.ok && data.parsedBody) {
                        setIsDomainValid(false);
                        if (data.parsedBody === "Insufficient credits") {
                            handleError(t("Domain_error_credit"))
                        } else {
                            handleError(t("Domain_error"))
                        }
                        return;
                    } else {
                        if (data.parsedBody !== undefined) {
                            setRequestResponse(data.parsedBody);

                            const newCompleted = new Set(completed);
                            newCompleted.add(activeStep);
                            setCompleted(newCompleted);

                            if (completed.size !== remainingSteps()) {
                                handleNext();
                            }

                        }
                    }

                }).catch(() => {
                    setRequesting(false);
                    handleError(t("Domain_error"))
                })
                break;
            case 2:

                setRequesting(true);

                APIGet<object>(REACT_APP_API_URL + "/domains/" + requestResponse?.domain_uuid + "/check").then((data) => {
                    setRequesting(false);
                    if (data.status > 200) {
                        handleError(t("error_domains_check"))
                    } else {
                        const newCompleted = new Set(completed);
                        newCompleted.add(activeStep);
                        setCompleted(newCompleted);

                        if (completed.size !== remainingSteps()) {
                            handleNext();
                        }
                    }

                }).catch(() => {
                    setRequesting(false);
                    handleError(t("error_domains_check"))
                })
                break;
            case 3:

                setRequesting(true);

                let req = {};
                if (orgShared) {
                    req = {
                        orgs_ids: [currentOrg?.organisation_id]
                    }
                } else {
                    req = {
                        users_ids: selectUsers?.map((user: User) => user.user_id)
                    }
                }

                APIPut<any>(REACT_APP_API_URL + "/domains/" + requestResponse?.domain_uuid, req).then((data) => {
                    setRequesting(false);
                    if (data.status > 200) {
                        handleError(t("error_domain_sharing"))
                    } else {
                        const newCompleted = new Set(completed);
                        newCompleted.add(activeStep);
                        setCompleted(newCompleted);

                        if (completed.size !== remainingSteps()) {
                            handleNext();
                        }
                    }
                })
                break;
            default:
                const newCompleted = new Set(completed);
                newCompleted.add(activeStep);
                setCompleted(newCompleted);

                if (completed.size !== 5 - skippedSteps()) {
                    handleNext();
                }
        }
    };

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <Container maxWidth="md">
                        <Grid container direction="row" spacing={3} alignItems="center" justifyContent="center">
                            <Grid item md={12} style={{padding: '12px'}}>
                                <Typography variant="body1" style={{fontWeight: 300}}>
                                    {t("Domain_names_intro_text")}
                                </Typography>
                            </Grid>
                            <Grid item md={4}>
                                <img style={{width: '100%'}} src={UnitagDomain} alt={"domain example"} />
                                <Typography style={{fontWeight: 300, textAlign: 'center', fontSize: '0.9rem'}}>
                                    {t('Unitag_regular_dn')}
                                </Typography>
                            </Grid>
                            <Grid item md={4}>
                                <img style={{width: '100%'}} src={YourDomain} alt={"domain example"} />
                                <Typography style={{fontWeight: 300, textAlign: 'center', fontSize: '0.9rem'}}>
                                    {t('Your_dn')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{padding: '12px'}}>
                                <Alert severity={"warning"} sx={{ width: "100%" }}>
                                    <Typography variant="body2">
                                        {t("Domain_confirmation", {val: 7.99, val2: 95.88})}
                                    </Typography>
                                </Alert>
                            </Grid>
                        </Grid>
                    </Container>
                );
            case 1:
                return (
                    <Container maxWidth="md">
                        <Grid container spacing={3} justifyContent="center" alignItems="center">
                            <Grid item md={6}>
                                <Grid container spacing={3} justifyContent="center" alignItems="center">
                                    <Grid item md={12}>
                                        <Typography variant="body1"
                                                    style={{fontWeight: 300}}>{t("Domain_request_headline")}</Typography>
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            error={!isDomainValid}
                                            fullWidth
                                            focused
                                            autoComplete="false"
                                            autoCorrect="false"
                                            spellCheck="false"
                                            size="small"
                                            variant="outlined"
                                            color="secondary"
                                            label={t("Domain_name")}
                                            placeholder="qrcode.example.com"
                                            onChange={handleDomainNameChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <img style={{width: '70%'}} src={YourDomain} alt={"domain example"} />
                            </Grid>
                        </Grid>
                    </Container>
                );
            case 2:
                if (requestResponse !== null) {
                    if (requestResponse.request_type === "APEX") {
                        return (
                            <Container maxWidth="md">
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            style={{fontWeight: 300}}
                                        >
                                            {t("Domain_conf_text_1")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Alert
                                            severity="success"
                                            action={
                                                <IconButton
                                                    aria-label="copy"
                                                    size="small"
                                                    component="span"
                                                    onClick={() => {copyText(`${requestResponse.requested_domain} A ${requestResponse.target_ip}`)}}
                                                >
                                                    <FileCopyIcon/>
                                                </IconButton>
                                            }
                                        >
                                            {requestResponse.requested_domain} A {requestResponse.target_ip}
                                        </Alert>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1"
                                                    style={{fontWeight: 300}}>{t("Domain_conf_text_2")}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Alert
                                            severity="info"
                                        >
                                            {t("Domain_conf_note")}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            </Container>
                        );
                    } else {
                        return (
                            <Container maxWidth="md">
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Typography variant="body1"
                                                    style={{fontWeight: 300}}>{t("Domain_conf_text_1")}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Alert
                                            severity="success"
                                            action={
                                                <IconButton
                                                    aria-label="copy"
                                                    size="small"
                                                    component="span"
                                                    onClick={() => {
                                                        copyText(`${requestResponse?.requested_domain} CNAME ${requestResponse?.fqdn}`)
                                                    }}
                                                >
                                                    <FileCopyIcon/>
                                                </IconButton>
                                            }
                                        >
                                            {requestResponse?.requested_domain} CNAME {requestResponse?.fqdn}.
                                        </Alert>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1"
                                                    style={{fontWeight: 300}}>{t("Domain_conf_text_2")}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid item>
                                            <Alert
                                                severity="info"
                                            >
                                                {t("Domain_conf_note")}
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        );
                    }
                }
                break;
            case 3:
                return (
                    <div>
                        <Typography variant="body1">{t("Domain_security_text")}</Typography>
                        <br/>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={orgShared}
                                    onChange={() => setOrgShared(!orgShared)}
                                    name="share-all"
                                    color="secondary"
                                />
                            }
                            label={t("Domain_security_access_all") as string}
                        />
                        <br/>
                        <Typography variant="body1">{t("Domain_security_access_indiv")}</Typography>
                        <br/>

                        <Autocomplete
                            disabled={orgShared}
                            multiple
                            size="small"
                            fullWidth={false}
                            id="orgs-checkboxes"
                            disableCloseOnSelect
                            options={optionsUsers}
                            // onChange={handleSelectedUsersChange}
                            onChange={(_e, value) => setSelectedUsers(value)}
                            isOptionEqualToValue={(option, value) => {
                                const o = option as User;
                                const v = value as User;
                                return o.user_id === v.user_id;
                            }}
                            getOptionLabel={(option) => {
                                const o = option as User;
                                return o.email;
                            }}
                            open={openUsers}
                            onOpen={() => { setOpenUsers(true) }}
                            onClose={() => { setOpenUsers(false) }}
                            loading={loadingUsers}
                            renderOption={(props, option, {selected}) => {
                                const o = option as User;
                                return (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                            checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                            style={{marginRight: '8px'}}
                                            checked={selected}
                                        />
                                        {o.email}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={t("Users")}
                                           placeholder={t("Select_users")}/>
                            )}
                        />
                        <br/>
                    </div>
                );
            case 4:
                return (
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Grid item>
                                <Typography variant="body1" style={{
                                    fontWeight: 500,
                                    fontSize: '1.5rem'
                                }}>{t("Congratulations")}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1"
                                            style={{fontWeight: 300}}>{t("Domain_finish_text")}</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                );
            default:
                return null;
        }
    }

    const renderStepper = () => {

        // Check if step is optional
        const isStepOptional = (step: number) => { return step === 2 || step === 3; };

        // Manage back button
        const handleBack = () => { setActiveStep((prevActiveStep) => prevActiveStep - 1); };

        // Manage skip step
        const handleSkip = () => {
            if (!isStepOptional(activeStep)) {
                // You probably want to guard against something like this
                // it should never occur unless someone's actively trying to break something.
                throw new Error("You can't skip a step that isn't optional.");
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped((prevSkipped) => {
                const newSkipped = new Set(prevSkipped.values());
                newSkipped.add(activeStep);
                return newSkipped;
            });
        };

        // Check if step is skipped
        const isStepSkipped = (step: number) => {
            return skipped.has(step);
        };

        return (
            <>
                <div>
                    <Stepper
                        style={{padding: '24px'}}
                        alternativeLabel
                        nonLinear
                        activeStep={activeStep}
                    >
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};

                            if (isStepSkipped(index)) stepProps.completed = false

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <div>
                        {!allStepsCompleted() && (
                            <div>

                                {getStepContent(activeStep)}
                                <br/>

                                <div style={{textAlign: "right"}}>
                                    <Button text disabled={activeStep === 0} onClick={handleBack} spacing>
                                        {t("Back")}
                                    </Button>

                                    {isStepOptional(activeStep) && !completed.has(activeStep) && (
                                        <Button secondary onClick={handleSkip}>
                                            {t("Skip")}
                                        </Button>
                                    )}

                                    {requesting ?
                                        <Button secondary spacing>
                                            <CircularProgress size={18}/>
                                        </Button>
                                        :
                                        <Button secondary spacing onClick={handleComplete}>
                                            {completedSteps() === 4 ? `${t("Finish")}` : `${t("Next")}`}
                                        </Button>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }

    return (
        <main style={classes.content}>
            <Container maxWidth="xl">
                <Grid container spacing={0} justifyContent="flex-start" alignItems="baseline">
                    <Grid item sx={{flexGrow: 1, my: 2}}>
                        <Typography variant="h5">
                            {t("Domain_names")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            primary
                            small
                            disabled={domainsList === undefined || !authorisations.domains}
                            startIcon={<AddCircleOutlineIcon/>}
                            onClick={handleOpenDomainsDialog}
                            sx={{ margin: "8px 0"}}
                        >
                            {t("Domain_add")}
                        </Button>

                    </Grid>
                    <Grid item sm={12}>
                        <Divider/>
                    </Grid>
                    {
                        // if not allowed to use the app
                        (!!complexUser && (complexUser.organisation_role === "MEMBER" || complexUser.organisation_role === "ANALYST")) ?
                            <Grid item xs={12}>
                                <SettingsUpgradeMessage
                                    unauthorized
                                    message={"members_cant_access"}
                                    {...{t}}
                                />
                            </Grid>
                        : !authorisations.domains ?
                            <Grid item xs={12}>
                                <SettingsUpgradeMessage
                                    accountType={user.account_type}
                                    message={"Domains_plan_needed"}
                                    {...{t}}
                                />
                            </Grid>
                        : // else, display the app
                            <>
                                <Grid item xs={12} sx={{my: 2}}>
                                    <Typography variant="body2">{t("Domain_names_headline")}</Typography>
                                </Grid>

                                <br/><br/>

                                {
                                    loading ? <Grid item xs={12}><Skeleton variant={"rectangular"} height={250} /></Grid>
                                    : !!domainsList ? renderDataTable()
                                    : <Grid item md={12}>{renderStepper()}</Grid>
                                }
                            </>
                    }

                </Grid>

                {renderSharingDialog()}

                <CreateDomainDialog
                    open={openDomainsDialog}
                    onClose={handleCloseDomainsDialog}
                    {...{renderStepper, t}}
                />

                <ConfigDomainDialog
                    open={openConfigDialog}
                    onClose={() => setOpenConfigDialog(false)}
                    {...{handleSuccess, handleError, domainConfig, t}}
                />

                <DeleteDomainDialog
                    open={openDeleteDialog}
                    domainId={currentDomainID}
                    domainName={currentDomainName}
                    onClose={closeDeleteDialog}
                    {...{handleSuccess, handleError, t}}
                />

            </Container>
        </main>
    );
}
