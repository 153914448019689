import React, {createContext, useContext, useEffect, useState} from "react";
import {useUser} from "@context";
import {CreditsResponse} from "@interfaces";

// This component is rather complex and built atop a sweetly (stupidly) built backend permissions system
// where multiple instances of a same value can be found in several responses.
// In order to put that to rest, we create a synthesis here to centralize everything

const PermissionsContext = createContext<any>(null)

export const usePermissions = () => useContext(PermissionsContext)

export function PermissionsProvider({children}: any) {

    const {complexUser, credits, getCredits, organisationAsMember} = useUser()

    const [loadingPermissions, setLoadingPermissions] = useState<boolean>(true)
    const [accessPermissions, setAccessPermissions] = useState<undefined | Map<string, boolean>>(undefined)
    const [usagePermissions, setUsagePermissions] = useState<undefined | Map<string, boolean>>(undefined)
    // Account Type
    const [accountType, setAccountType] = useState(null)
    // Organisation Details
    const [hasOrganisation, setHasOrganisation] = useState(false)
    const [isSubOrganisation, setIsSubOrganisation] = useState(false)
    const [userRole, setUserRole] = useState("")
    // const [organisationAccountType, setOrganisationAccountType] = useState(null)
    // True account type (user account type will be overridden by org account type)
    const [trueAccountType, setTrueAccountType] = useState(null)

    // Fetch credits
    useEffect(() => {
        if (!!credits) {
            mapAccessPermissions()
            mapUsagePermissions()
        } else getCredits()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credits, hasOrganisation])

    // Separate user and org account types, and set "true" account type
    useEffect(() => {
        if (!!complexUser) {
            let type = complexUser.account_type
            if (!!organisationAsMember) {
                setHasOrganisation(true)
                setUserRole(organisationAsMember.role)
                // setOrganisationAccountType(organisationAsMember.org_account_type)
                setIsSubOrganisation(!organisationAsMember.is_root_organisation)
                type = organisationAsMember.org_account_type
            }
            setAccountType(complexUser.account_type)
            setTrueAccountType(type)
        }
    }, [complexUser, organisationAsMember])

    useEffect(() => {
        if (!!accessPermissions && !!usagePermissions) setLoadingPermissions(false)
    }, [accessPermissions, usagePermissions]);

    // Credits checkers
    const isAppLabel = (labels: string[], app: any) => {
        return Boolean(labels.find((label: string) => label === app.service_name))
    }

    const hasOrHadCredits = (app: any) => {
        return (app.credits_left > 0 || app.credits_used > 0)
    }

    const hasCreditsLeft = (app: any) => {
        return app.credits_left > 0
    }

    // Account checkers
    // // Types : "free" | "standard" | "gold" | "platinum" | "standalone"
    const isAccountTier = (tier: string[]) => {
        return Boolean(tier.find((e: string) => trueAccountType === e))
    }

    // // Types : "member" | "admin" | "analyst" | "owner"
    const isAccountRole = (role: string[]) => {
        if (!userRole) return false
        return Boolean(role.find((e: string) => userRole.toLowerCase() === e))
    }

    // Access permissions (has proper role or account type)
    // Every access point is keyed "FEATURE.access"
    const mapAccessPermissions = () => {
        let _permissions = new Map()

        _permissions.set("console", true)

        // Parse credits and set apps access permissions
        credits.forEach((appCredits: CreditsResponse) => {
            if (isAppLabel(["packaging", "packaging_gs1"], appCredits) && hasOrHadCredits(appCredits)) _permissions.set("packaging.access", true)
            else if (isAppLabel(["vcards"], appCredits) && hasOrHadCredits(appCredits)) _permissions.set("vcards.access", true)
            else if (isAppLabel(["winelabel"], appCredits) && hasOrHadCredits(appCredits)) _permissions.set("winelabel.access", true)
        });

        // Other apps
        if (isAccountTier(["gold", "platinum"])) _permissions.set("campaigns.access", true)
        if (isAccountTier(["gold", "platinum"])) _permissions.set("qrcodes.filters.access", true)
        if (isAccountTier(["gold", "platinum"])) _permissions.set("qrcodes.healthchecks.access", true)
        if (isAccountTier(["standard", "gold", "platinum"])) _permissions.set("analytics.access", true)

        // Settings
        // // Orga
        if (userRole) _permissions.set("organisations.access", true)
        // // Domains
        if (isAccountTier(["gold", "platinum"])) _permissions.set("domains.access", true)
        // // API
        if (isAccountTier(["standard", "gold", "platinum"])) _permissions.set("api.access", true)
        // // Theming
        if (isAccountTier(["platinum"])) _permissions.set("theming.access", true)

        // Set state
        setAccessPermissions(_permissions)
    }

    // Usage permissions (has credits)
    const mapUsagePermissions = () => {
        let _permissions = new Map()

        _permissions.set("console", true)

        // Authorisations are validated for Atlas in the same time
        credits.forEach((appCredits: CreditsResponse) => {
            // packaging
            if (
                isAppLabel(["packaging"], appCredits) &&
                hasCreditsLeft(appCredits)
            ) {
                _permissions.set("packaging.create", true)
                _permissions.set("atlas.packaging.batch", true)
            }
            // GS1
            else if (
                isAppLabel(["packaging_gs1"], appCredits) &&
                hasCreditsLeft(appCredits)
            ) _permissions.set("packaging.gs1.create", true)
            // QR Codes
            else if (
                isAppLabel(["qrcodes"], appCredits) &&
                hasCreditsLeft(appCredits)
            ) {
                _permissions.set("qrcodes.create", true)
                // This part seems a little obscure but it worked like that in Atlas so far
                if (isAccountTier(["standard", "gold", "platinum"])) {
                    _permissions.set("atlas.qrcodes.batch", true)
                }
                // QR Codes list & actions TODO: improve logic not to exclude free/std
                // if (isAccountRole(["member", "admin", "owner"])) {
                //     _permissions.set("qrcodes.edit", true)
                //     _permissions.set("qrcodes.delete", true)
                //     _permissions.set("qrcodes.design", true)
                //     _permissions.set("qrcodes.templates.manage", true)
                // }
            }
            // Healthchecks
            else if (
                isAppLabel(["healthchecks"], appCredits) &&
                hasCreditsLeft(appCredits)
            ) _permissions.set("qrcodes.healthchecks.create", true)
            // Domains
            else if (
                isAppLabel(["domains"], appCredits) &&
                hasCreditsLeft(appCredits) &&
                isAccountRole(["owner"])
            ) _permissions.set("domains.create", true)
            // vCards
            else if (
                isAppLabel(["vcards"], appCredits) &&
                hasCreditsLeft(appCredits)
            ) {
                _permissions.set("vcards.create", true)
                _permissions.set("atlas.vcards.batch", true)
            }

            // Usage not relying on credits
            // // API Keys
            if (
                (hasOrganisation && isAccountRole(["owner"])) ||
                !hasOrganisation
            ) _permissions.set("api.create", true)
            // // Organisations
            if (hasOrganisation && isAccountRole(["owner"])) _permissions.set("organisations.users.buy", true)
            if (hasOrganisation && isAccountRole(["owner", "admin"])) _permissions.set("organisations.manage", true)
            if (hasOrganisation && isAccountTier(["platinum"])) _permissions.set("organisations.suborganisations.create", true)
            if (hasOrganisation && !isSubOrganisation) _permissions.set("organisations.suborganisations.manage", true)
        });

        setUsagePermissions(_permissions)
    }


    const hasAccess = (feature: string) => {
        if (!accessPermissions || !accessPermissions.has(feature)) return false
        return accessPermissions.get(feature)
    }

    const hasUsage = (feature: string) => {
        if (!usagePermissions || !usagePermissions.has(feature)) return false
        return usagePermissions.get(feature)
    }

    // Returns an object with each tier as a boolean or "up"
    // You check the tier access by name, so you must pass the account type AND a tier
    // "true" means that this is the actual tier, "up" means that you have a better plan
    const returnTierList = (accountType: any, checkedTier: any) => {
        let subscriptions: any = {
            standard: false,
            gold: false,
            platinum: false,
            standalone: false
        }

        switch (accountType) {
            case "standalone":
            case "vcard pro":
                subscriptions.standalone = true
                break;
            case "standard":
                subscriptions.standard = true
                subscriptions.standalone = "up"
                break;
            case "gold":
                subscriptions.standard = "up"
                subscriptions.standalone = "up"
                subscriptions.gold = true
                break;
            case "platinum":
                subscriptions.standard = "up"
                subscriptions.standalone = "up"
                subscriptions.gold = "up"
                subscriptions.platinum = true
                break;
        }

        return subscriptions[checkedTier]
    }

    return (
        <PermissionsContext.Provider value={{
            // For logging purposes only
            usagePermissions, accessPermissions,
            // Main
            usePermissions, loadingPermissions,
            // permissions checkers
            hasAccess, hasUsage, returnTierList,
            isAccountTier, isAccountRole,
            // account checkers
            userRole,
            accountType, trueAccountType,
            hasOrganisation,
        }}>
            {children}
        </PermissionsContext.Provider>
    )
}