import React from "react"
// MUI
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Components
import {Button} from "@components";

export default function UnsubscribeFromModuleDialog(props: any) {

    const {open, onClose, onUnsubscribe, t} = props

    return (
        <>
            <Dialog
                key={'confirmdelete'}
                fullWidth
                maxWidth={'sm'}
                open={open}
                keepMounted
                onClose={onClose}
            >
                <DialogTitle style={{padding: '24px'}}>
                    <Box display="flex" alignItems="center">
                        <Box style={{textAlign: 'left'}} flexGrow={1}>{t("Confirm_unsubscribe")}</Box>
                        <IconButton onClick={onClose}><CloseIcon/></IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent style={{padding: '24px'}}>
                    <Grid container justifyContent={'center'} alignItems="center">
                        <Grid item xs={12} md={10} lg={8}>
                            <Typography variant={"body1"}>
                                {t("Cancel_module_alert")}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions style={{padding: '24px'}}>
                    <Grid>
                        <Button onClick={onClose}>{t("Cancel")}</Button>&nbsp;
                        <Button primary onClick={onUnsubscribe}>{t("Cancel_module")}</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}