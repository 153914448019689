export const styles = {
    overviewData: {
        p: 1,
        height: 70,
        display: 'flex',
        flexDirection: 'column',
        alignContent: "center",
        justifyContent: "center"
    },
    overviewTooltipBox: {
      display: 'flex',
      flexDirection: "row",
      justifyContent: "center"
    },
    overviewAction: {
        borderRadius: "0 0 10px 10px"
    },
    formBalance: {
        mt: "4px",
        mx: 1
    },
    paper: {
        p: 2,
        borderRadius: 1,
    },
    greyedPaper: {
        backgroundColor: "#e8e8e8",
        p: 2
    },

    blockBalance: {
        mt: 2,
        borderRadius: 1,
    },
    listHeader: {
        backgroundColor: "#e8e8e8",
    },
    dataTable: {
        width: "100%"
    },
    filtersButtonJustification: {
        mt: "4px",
        textAlign: "right"
    },
    userRole: {
        fontStyle: "italic"
    },
    alignRight: {
        textAlign: "right",
    },

    fullWidthPaper: {
        p: 2,
        mt: 2,
        bgcolor: "#f8f6fa",
        w: "100%"
    }
}
