// REACT
import React, {useEffect, useState} from 'react';
// MUI
import {Divider, Grid, Link, Paper, Skeleton, Typography} from '@mui/material';
// COMPONENTS
import UnsubscribeFromPlanDialog from "./dialogs/UnsubscribeFromPlanDialog";
import BillingOverviewPaymentMethods from "./components/BillingOverviewPaymentMethods";
import BillingOverviewInformation from "./components/BillingOverviewInformation";
// API
import {APIPost} from "@api";
// Interfaces
import {GetCurrentInvoiceResponse, PaymentMethod, SubscriptionItems} from "@interfaces";
// TRANSLATION
import {Trans, useTranslation} from 'react-i18next';
// STRIPE
import {useStripe} from "@stripe/react-stripe-js";
// Unitag UI
import {Button} from '@components';
// Context
import {useUser} from "@context";
// Utils
import {freeSubscription} from "@/constants/plans_en";
// Hooks
import {usePermissions} from "@/Context/User/PermissionsProvider";

const {REACT_APP_API_URL} = process.env;

export default function BillingOverviewTab(props: any) {

    const {handleSuccess, handleError, handleTabSwitch} = props;
    const {trueAccountType} = usePermissions();

    const {t} = useTranslation(['settings', 'common']);
    const stripe = useStripe();
    const {
        creditsDetails, getCreditsDetails,
        billingMethod, getBillingMethod,
        organisationAsMember,
        billingInvoice, getBillingInvoice
    } = useUser()

    const [loading, setLoading] = useState<boolean>(false);

    const [currentInvoice, setCurrentInvoice] = useState<GetCurrentInvoiceResponse>({
        amount_due: 0,
        cancel_at: 0,
        due_date: 0,
        currency: "",
        trial_days_left: 0,
        is_in_trial: false,
        invoice_pdf: "",
        items: [] as SubscriptionItems[],
        subscription: {
            amount: 0,
            type: ""
        }
    });
    // Access
    const [canAccessPayment, setCanAccessPayment] = useState<boolean>(false);
    // User data
    const [renewalDate, setRenewalDate] = useState<string>('--');
    const [subscriptionInterval, setSubscriptionInterval] = useState<string | null>(null);
    const [totalDue, setTotalDue] = useState(0);
    // Billing Method
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>(null);
    const [paymentLoading, setPaymentLoading] = useState<boolean>(true);
    // Unsubscribe
    const [confirmUnsubscribe, setConfirmUnsubscribe] = useState<boolean>(false);
    const [unsubscribeReason, setUnsubscribeReason] = useState<number | any>(1);
    const [unsubscribeFeedback, setUnsubscribeFeedback] = useState<string | null>(null);

    useEffect(() => {
        setPaymentLoading(true)
        getCreditsDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Load user data
    useEffect(() => {
        let _canAccessPayment: boolean;
        // The user is part of an org
        if (!!organisationAsMember && organisationAsMember.organisation_id !== '') {
            _canAccessPayment = organisationAsMember.is_root_organisation && organisationAsMember.role === "OWNER"
        } else _canAccessPayment = true

        setCanAccessPayment(_canAccessPayment)

        if (_canAccessPayment) {
            getBillingMethod()
            getBillingInvoice()
        } else setPaymentLoading(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationAsMember])

    // Load payment methods
    useEffect(() => {
        if (!!billingMethod) {
            // Set payment methods
            setPaymentMethods(billingMethod.payment_methods)
            // Or remove loading when no payment method is detected
        } else if (billingMethod === null) setPaymentLoading(false)
    }, [billingMethod])

    // Load invoice
    useEffect(() => {
        if (!!billingInvoice) {
            setCurrentInvoice(billingInvoice);
            const dueDate: number = billingInvoice.due_date;
            if (dueDate !== 0) {
                const _renewalDate = new Date(dueDate * 1000).toLocaleString(navigator.language);
                setRenewalDate(_renewalDate.split(",")[0]);
            }
        }
    }, [billingInvoice])

    // Specific use of loading
    useEffect(() => {
        if (!!paymentMethods && paymentMethods.length > 0) setPaymentLoading(false)
    }, [paymentMethods])

    // Interval
    useEffect(() => {
        if (!!creditsDetails && !!creditsDetails.subscription) {
            returnTotalDue()

            if (creditsDetails.subscription.interval === "yearly") setSubscriptionInterval(`yearly`)
            else setSubscriptionInterval("monthly")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creditsDetails])

    // Get total due
    const returnTotalDue = () => {
        if (!creditsDetails) return

        let services = creditsDetails.subscription_items
        let planPrice = creditsDetails.subscription.price

        if (!!services && services.length > 0) {
            let servicesPrice = services.reduce((acc: any, service: any) => acc + service.price, 0);
            let total = planPrice + servicesPrice
            let totalRounded = Math.floor(total * 100) / 100
            let due = totalRounded.toFixed(2)
            setTotalDue(due as unknown as number);
        } else {
            setTotalDue(planPrice)
        }
    }

    const handleUnsubscribeClick = () => {
        if (unsubscribeReason !== null) {
            setLoading(true);

            APIPost<any>(REACT_APP_API_URL + "/billing/unsubscribe", {
                reason: unsubscribeReason,
                feedback: unsubscribeFeedback
            }).then((data) => {

                if (data.status !== 200) handleError(t("error_unsubscribing"))
                else handleSuccess(t("success_unsubscribing"))

                setLoading(false);
                setConfirmUnsubscribe(false);
                setUnsubscribeFeedback(null);
                setUnsubscribeReason(1);
                return
            }).catch(() => handleError(t("error_unsubscribing"))
            ).finally(() => getCreditsDetails());
            return
        }

        setLoading(true);

        APIPost<any>(REACT_APP_API_URL + "/billing/unsubscribe", null).then((data) => {
            if (!data.ok) handleError(t("error_unsubscribing"))
            else handleSuccess(t("success_unsubscribing"))

            setLoading(false);
            setConfirmUnsubscribe(false);
        }).catch(() => handleError(t("error_unsubscribing"))
        ).finally(() => getCreditsDetails());
    }

    const renderUnsubscribe = () => {

        const returnCancellationDate = (): string => {
            return new Date(currentInvoice.cancel_at * 1000).toLocaleString(navigator.language);
        }

        const returnBlockContent = () => {
            if (!!currentInvoice && !!creditsDetails && creditsDetails.cancel_at !== 0) {
                if (!currentInvoice.is_in_trial) {
                    // Was paying
                    return (
                        <Grid item xs={12}>
                            <Typography>{t('Cancel_confirmation')} {returnCancellationDate()} </Typography>
                        </Grid>
                    )
                    // Was in trial
                } else return (
                    <Grid>
                        <i style={{fontSize: '12px'}}>{t("Unsubscribe_success")}</i>
                    </Grid>
                )
            } else if (!!currentInvoice && (currentInvoice.items !== null || currentInvoice.subscription.amount !== 0)) {
                // Is actually registered as subscribed
                return (
                    <>
                        <Grid item xs={12} md={9}>
                            <Typography variant={"body2"}>
                                <Trans t={t} i18nKey={"unsubscribe_from_plan"}>
                                    e<Link sx={{ cursor: "pointer" }} onClick={() => handleTabSwitch(1)}>e</Link>e
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ textAlign: "center", alignItems: "center"}}>
                            <Button
                                disabled={!canAccessPayment}
                                delete
                                onClick={() => setConfirmUnsubscribe(true)}
                            >
                                {t("Unsubscribe")}
                            </Button>
                        </Grid>
                    </>
                )
            } else return <Grid item xs={12}> <Skeleton width={"100%"} height={50} /></Grid>
        }

        return (
            <>
                <Grid item xs={12}>
                    <Divider textAlign={"left"}>
                        <Typography variant={"h6"} component={"p"}>
                            {t("Unsubscribe")}
                        </Typography>
                    </Divider>
                </Grid>
                <Grid item xs={12}>
                    <Paper variant={"outlined"} sx={{ backgroundColor: "#ef535020", p: 2 }}>
                        <Grid container spacing={2}>
                            {returnBlockContent()}
                        </Grid>
                    </Paper>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid container sx={{ my: 3 }}>

                {/* Information */}
                <Grid item xs={12} container spacing={2}>
                    <BillingOverviewInformation
                        {...{
                            handleTabSwitch,
                            totalDue,
                            creditsDetails, subscriptionInterval,
                            canAccessPayment,
                            currentInvoice,
                            renewalDate,
                            t
                        }}
                    />
                </Grid>

                {/* Payment methods*/}
                <Grid item xs={12} sx={{ display: trueAccountType === freeSubscription ? "none" : "inline-block" }}>
                    <BillingOverviewPaymentMethods
                        isInTrial={creditsDetails?.is_trial}
                        {...{
                            getBillingMethod,
                            paymentMethods,
                            paymentLoading, setPaymentLoading,
                            canAccessPayment,
                            handleSuccess, handleError,
                            stripe, loading, setLoading, t
                        }}
                    />
                </Grid>

                <Grid item xs={12} container spacing={2} sx={{ display: trueAccountType === freeSubscription ? "none" : "inline-block" }}>
                    {renderUnsubscribe()}
                </Grid>
            </Grid>

            <UnsubscribeFromPlanDialog
                {...{
                    confirmUnsubscribe, setConfirmUnsubscribe,
                    unsubscribeReason, setUnsubscribeReason,
                    loading, stripe, handleUnsubscribeClick
                }}
            />
        </>
    );
}
