import {Box, Paper, Typography} from "@mui/material";
import React from "react";

function PlanPaper(props: any) {

    const {color, enterprise, popular, pinned, children, smallDevice, t} = props

    const styles = {
        paper: {
            p: 2,
            borderRadius: 2,
            borderColor: color ? color : "divider",
            backgroundColor: enterprise ? "enterprise.main" : "white",
            position: "relative",
            overflow: "hidden",
        },
        chip: {
            position: "absolute",
            right: pinned && !smallDevice ? "-100px" : 0,
            top: 22,
            backgroundColor: "platinum.main",
            borderRadius: "8px 0 0 8px",
            py: "4px",
            px: 1,
            transition: "right 0.5s ease-in-out"
        }
    }

    const renderPopularChip = () => {
        return (
            <>
                <Box sx={styles.chip}>
                    <Typography fontSize={12} component={"p"} color={"white"}>{t("most_popular")}</Typography>
                </Box>
            </>
        )
    }

    return (
        <>
            <Paper sx={styles.paper} variant={"outlined"}>
                {!!popular && renderPopularChip()}
                {children}
            </Paper>
        </>
    )
}

export default PlanPaper;
