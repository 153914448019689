import React, {useState} from "react"
// MUI
import {
    Alert,
    Box,
    CircularProgress, Divider,
    Grid, List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText, Skeleton, Typography
} from "@mui/material";
import {Button} from "@components";
// Assets
import {ReactComponent as VISACardLogo} from "@assets/svg/card-visa.svg";
import {ReactComponent as MasterCardLogo} from "@assets/svg/card-mastercard.svg";
import {ReactComponent as AmexCardLogo} from "@assets/svg/card-amex.svg";
// Components
import {AddPaymentMethodDialog} from "../dialogs";
import GenericActionDialog from "@components/dialogs/GenericActionDialog";
// Stripe
import {StripeCardElement, StripeCardNumberElement, TokenResult} from "@stripe/stripe-js";
import {CardElement, useElements} from "@stripe/react-stripe-js";
// API
import {APIDelete, APIPost, APIPut} from "@api";
// Interfaces
import {AddPaymentResponseMessage} from "@interfaces";

const {REACT_APP_API_URL} = process.env;

export default function BillingOverviewPaymentMethods(props: any) {

    const {
        isInTrial,
        getBillingMethod,
        paymentMethods,
        paymentLoading, setPaymentLoading,
        handleSuccess, handleError,
        stripe, loading, setLoading, t
    } = props

    const elements = useElements();

    // Dialogs management
    const [openAddPaymentDialog, setOpenAddPaymentDialog] = useState<boolean>(false);
    const [openDeletePaymentDialog, setOpenDeletePaymentDialog] = useState<boolean>(false);
    // Payment method selection
    const [toDelete, setToDelete] = useState<string>("");

    // Default card
    const handleDefaultCardChange = (cardID: string) => {
        APIPut<AddPaymentResponseMessage>(REACT_APP_API_URL + "/billing/update_default_card", {
            payment_id: cardID,
        }).then((data) => {
            setLoading(false);
            if (!data.ok) {
                handleError(t("error_default_payment_change"))
                const error = (data && data.parsedBody!.toString()) || data.status;
                return Promise.reject(error);
            } else {
                handleSuccess(t("success_default_payment_change"))
            }
        }).catch(() => {
            handleError(t("error_default_payment_change"))
        }).finally(() => {
            getBillingMethod()
        });
    }

    // Handle add payment method
    const handleNewCardSubmit = async (event: any) => {
        setLoading(true)
        event.preventDefault()

        const _card: StripeCardElement | StripeCardNumberElement = elements!.getElement(CardElement) as StripeCardElement
        const result = await stripe!.createToken({..._card})

        await addNewPaymentMethod(result)
    };

    const addNewPaymentMethod = async (result: TokenResult) => {
        setPaymentLoading(true)
        if (result.error) {
            handleError(t("error_add_payment_method"))
            setLoading(false)
            setPaymentLoading(false)
        } else {
            // Otherwise send paymentMethod.id to your server (see Step 3)
            APIPost<AddPaymentResponseMessage>(REACT_APP_API_URL + "/billing/payment_methods", {
                token: result.token,
            }).then((data) => {
                if (!data.ok) {
                    handleError(t("error_add_payment_method"))
                    const error = (data && data.parsedBody!.toString()) || data.status;
                    return Promise.reject(error);
                }
                setOpenAddPaymentDialog(!openAddPaymentDialog);
                handleSuccess(t("success_add_payment_method"))
            }).catch(() => {
                handleError(t("error_add_payment_method"))
            }).finally(() => {
                getBillingMethod()
                setLoading(false)
            });

        }
    };

    // Handle delete payment method
    const handleOpenDeletePaymentMethodDialog = (payment_id: string) => {
        setToDelete(payment_id);
        setOpenDeletePaymentDialog(true);
    }

    const handleCloseDeletePaymentMethodDialog = () => {
        setToDelete("");
        setOpenDeletePaymentDialog(false);
    }

    const deletePaymentMethod = (payment_id: string) => {
        setPaymentLoading(true)
        if (!!payment_id) {
            setLoading(true);
            APIDelete<AddPaymentResponseMessage>(REACT_APP_API_URL + "/billing/payment_methods", {
                payment_id: payment_id,
            }).then((data) => {
                setLoading(false);
                if (!data.ok) {
                    handleError(t("error_delete_payment_method"))
                    setPaymentLoading(false)
                    const error = (data && data.parsedBody!.toString()) || data.status;
                    return Promise.reject(error);
                }
                handleSuccess(t("success_delete_payment_method"))
                setToDelete("");
                setOpenDeletePaymentDialog(false);
            }).catch(() => {
                setPaymentLoading(false)
                setToDelete("");
                setOpenDeletePaymentDialog(false);
                handleError(t("error_delete_payment_method"))
            }).finally(() => {
                setLoading(false)
                getBillingMethod()
            });
        }
    }

    // Display
    const returnHeader = () => {

        const returnNoPaymentMessage = () => {
            if ((!paymentMethods || paymentMethods.length === 0) && !paymentLoading) {
                if (isInTrial) return <Alert severity={"info"} sx={{mb: 2}}>{t("trial_payment_method")}</Alert>
                else return <Alert severity={"error"} sx={{mb: 2}}>{t("no_payment_method")}</Alert>
            } else return <></>
        }

        return (
            <>
                <Grid item xs={12} sx={{ mt: 5, mb: 3 }}>
                    <Divider textAlign={"left"}>
                        <Typography variant={"h6"} component={"p"}>
                            {t("Billing_method")}
                        </Typography>
                    </Divider>
                </Grid>

                <Grid item xs={12}>
                    {returnNoPaymentMessage()}
                    <Button primary onClick={() => setOpenAddPaymentDialog(true)} disabled={paymentLoading}>
                        {t("Add_payment_method")}
                    </Button>
                </Grid>
            </>
        )
    }

    const returnPaymentMethods = () => {
        return (
            <List>
                {paymentLoading && <Box sx={{ width: '100%', textAlign: 'center', height: '46px', py: 2 }}><CircularProgress color={"primary"} /></Box>}

                {paymentMethods !== null && paymentMethods !== undefined && paymentMethods.length > 0 &&
                    paymentMethods.map((e: any, index: number) => {
                        let cardSVG: any = {};
                        switch (e.payment_type) {
                            case "visa":
                                cardSVG = <VISACardLogo/>;
                                break;
                            case "mastercard":
                                cardSVG = <MasterCardLogo/>;
                                break;
                            case "amex":
                                cardSVG = <AmexCardLogo/>;
                        }

                        const secondaryText = `${t("Ends_in")}` + e.last_4 + `${t("And_expires_on")}` + e.card_expiry;
                        return (
                            <ListItem key={index} dense={true} style={{
                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                backgroundColor: "#f6f8fa",
                                marginBottom: '5px'
                            }}>
                                <ListItemIcon>
                                    {cardSVG}
                                </ListItemIcon>
                                <ListItemText primary={e.payment_type.toUpperCase()}
                                              secondary={secondaryText} style={{paddingLeft: '8px'}}/>
                                <ListItemSecondaryAction>
                                    <Grid container alignItems="flex-end" justifyContent="center">
                                        <Grid item>
                                            {e.is_default_payment ?
                                                <Button small text disabled spacing>{t("Default")}</Button>
                                                :
                                                <Button small text secondary spacing
                                                        onClick={() => handleDefaultCardChange(e.payment_id)}
                                                >{t("Make_default")}</Button>
                                            }

                                        </Grid>
                                        <Grid item>
                                            <Button
                                                disabled={e.is_default_payment}
                                                onClick={() => handleOpenDeletePaymentMethodDialog(e.payment_id)}
                                                delete
                                                text
                                                small
                                            >
                                                {t("Delete")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })
                }
            </List>
        )
    }

    if (paymentLoading) {
        return (
            <>
                <Grid container spacing={2} sx={{ my: 3}}>
                    <Grid item xs={12}>
                        <Skeleton variant={"rectangular"} height={36} sx={{ borderRadius: "8px" }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Skeleton variant={"rectangular"} height={61} sx={{ borderRadius: "8px" }} />
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            {returnHeader()}

            <Grid item xs={12}>
                {returnPaymentMethods()}
            </Grid>


            <AddPaymentMethodDialog
                open={openAddPaymentDialog}
                onClose={() => setOpenAddPaymentDialog(false)}
                onAdd={handleNewCardSubmit}
                {...{loading, stripe, t}}
            />

            <GenericActionDialog
                open={openDeletePaymentDialog}
                onClose={handleCloseDeletePaymentMethodDialog}
                title={t("Delete_payment_method")}
                color={"error"}
                text={t("Delete_payment_method_text")}
                primaryAction={() => { deletePaymentMethod(toDelete) }}
                primaryActionText={t("common:Delete")}
                loading={loading}
            />
        </>
    )
}