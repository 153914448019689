import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography} from "@mui/material";
import {Check, Close, ExpandMore} from "@mui/icons-material";
import React from "react";

function PlansDetailsAccordion(props: any) {

    const {title, rows, subtext, t} = props

    const styles = {
        wrapper: { overflow: "visible" },
        content: { display: ["none", "block" ] },
        accordion: {
            '&::before': {
                display: 'none!important',
            },
            overflow: "hidden",
            boxShadow: "none",
            border: "1px solid",
            borderColor: "divider"
        },
        accordionSummary: { backgroundColor: "#f5f5f5", fontWeight: 600 },
        accordionDetails: { p: 0, pl: 1, overflow: "hidden" },
        divider: { ml: "-16px" },
        rowElement: {
            textAlign: "center",
            px: { xs: 1, md: 2 },
            py: 2,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            '&:not(:first-child)': {
                borderLeft: "1px solid",
                borderColor: "divider",
                backgroundColor: "#fcfbfc",
            },

        }
    }

    const returnRowContent = (content: string | boolean) => {
        switch (typeof content) {
            case "string":
                if (content === "Unlimited") return <Typography color={"secondary"} fontWeight={500}>{t(content)}</Typography>
                return <Typography>{content}</Typography>
            case "boolean":
                if (content) return <Check sx={{ mb: "-6px", color: "standard.main" }} />
                return <Close sx={{ mb: "-6px"}} />
            default: return content
        }
    }

    return (
        <Box my={3} sx={styles.wrapper}>
            <Grid container columns={15}>
                <Grid item xs={15} lg={12} sx={styles.content}>
                    <Accordion defaultExpanded sx={styles.accordion}>
                        <AccordionSummary expandIcon={<ExpandMore />} sx={styles.accordionSummary}>{title}</AccordionSummary>
                        <AccordionDetails sx={styles.accordionDetails}>
                            {
                                rows.map((row: any, index: number) => (
                                    <Box key={title + index}>
                                        <Divider sx={styles.divider} />
                                        <Grid container key={index} columnSpacing={1} justifyContent={"center"}>
                                            <Grid item sx={styles.rowElement} xs={3}>{row.label}</Grid>
                                            <Grid item sx={styles.rowElement} xs={3}>{returnRowContent(row.standard)}</Grid>
                                            <Grid item sx={styles.rowElement} xs={3}>{returnRowContent(row.gold)}</Grid>
                                            <Grid item sx={styles.rowElement} xs={3}>{returnRowContent(row.platinum)}</Grid>
                                        </Grid>
                                    </Box>
                                ))
                            }
                        </AccordionDetails>
                    </Accordion>
                    { subtext && <Typography variant={"body2"} align={"right"} sx={{ mt: 1 }}>{subtext}</Typography> }
                </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
        </Box>
    )
}

export default PlansDetailsAccordion;