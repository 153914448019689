import React, {useState, useEffect} from 'react';
// MUI
import {
    FormControl, Grid, TextField, Container, Divider, Typography
} from '@mui/material';
// Unitag UI
import {Button, PasswordCheckTooltip} from '@components';
// TRANSLATION
import {useTranslation} from "react-i18next";
// AUTH
import {Auth} from "aws-amplify";
import TwoFASetup from "../components/Security/TwoFASetup";
import {useHistory} from "react-router-dom";
import {GeneratorPaper} from "@apps/QRCodesGenerator/components/UI";

export default function SettingsSecurityTab(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    const {handleError, handleSuccess} = props
    const history = useHistory();

    const classes = {
        textField: {marginLeft: '8px', marginRight: '8px', width: '300px', my: 0},
        form: { my: 1 }
    };

    const [newPassword, setNewPassword] = useState<string | null>(null);
    const [currentPassword, setCurrentPassword] = useState<string | null>(null);
    // Password validity
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
    // 2FA
    const [is2FAActive, set2FAActive] = useState<boolean>(false);
    const [open2FA, setOpen2FA] = useState<boolean>(false);

    useEffect(() => {
        // Check if 2FA is enabled
        Auth.currentAuthenticatedUser()
            .then(user => {
                Auth.getPreferredMFA(user, {bypassCache: true})
                    .then((data) => {
                        // If NOMFA then there is no MFA active
                        if (data === "NOMFA") set2FAActive(false);
                        else set2FAActive(true);

                })
            }).catch(() => handleError(t("unknown_error")))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Password updating
    const updatePassword = () => {
        if (currentPassword !== null && newPassword !== null) {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, currentPassword, newPassword);
                })
                .then(() => {
                    handleSuccess(t("Password_update_success"))
                    Auth.signOut({ global: true }).then(() => {
                        history.go(0);
                        history.push("/login");
                    })
                })
                .catch(() => handleError(t("Password_update_error")))
        }
    }

    // Renders
    const renderSecurityOptions = () => {

        const renderUpdatePassword = () => {
            return (
                <>
                    <Grid item xs={12}>
                        <GeneratorPaper label={t("Password_change")}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" sx={classes.form}>
                                        <TextField
                                            label={t("Password_old")}
                                            type="password"
                                            defaultValue=""
                                            sx={classes.textField}
                                            helperText={t("Password_old_label")}
                                            margin="dense"
                                            variant="outlined"
                                            size={"small"}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" sx={classes.form}>
                                        <PasswordCheckTooltip password={newPassword} {...{setIsPasswordValid}}>
                                            <TextField
                                                label={t("Password_new")}
                                                type="password"
                                                defaultValue=""
                                                error={!!newPassword && !isPasswordValid}
                                                sx={classes.textField}
                                                margin="dense"
                                                variant="outlined"
                                                size={"small"}
                                                onChange={(e) => setNewPassword(e.currentTarget.value)}
                                                disabled={!currentPassword}
                                            />
                                        </PasswordCheckTooltip>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        primary
                                        type="submit"
                                        onClick={updatePassword}
                                        disabled={!currentPassword || !newPassword || !isPasswordValid}
                                    >
                                        {t("Password_update")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </GeneratorPaper>
                    </Grid>
                </>
            )
        }

        const render2FA = () => {
            return (
                <>
                    <Grid item xs={12}>
                        <GeneratorPaper label={t("2fa")}>
                            <Typography variant='body1'>
                                {t("Security_two_factor_auth")}
                            </Typography>
                            <Button
                                primary
                                type="submit"
                                onClick={() => setOpen2FA(true)}
                                disabled={is2FAActive}
                            >
                                {t("Security_activate_2fa")}
                            </Button>
                        </GeneratorPaper>
                    </Grid>
                </>
            )
        }

        return (
            <Grid item xs={12} sx={{ mt: 1, pt: 1 }} container spacing={2}>
                {renderUpdatePassword()}
                {render2FA()}
            </Grid>

        )
    }

    return (
        <main>
            <Container maxWidth="xl">
                <Grid container spacing={0} justifyContent={'flex-start'}>

                    <Grid item xs={12} sx={{ flexGrow: 1, my: 2 }}>
                        <Typography variant='h5'>{t('Security')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>

                    {renderSecurityOptions()}
                </Grid>

                <TwoFASetup open={open2FA} onClose={() => setOpen2FA(false)}/>
            </Container>
        </main>
    );
}
