
const capitalizeFirstLetter = (str: string) => {
    return str[0].toUpperCase() + str.slice(1)
}

const roundToCent = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100
}

const formatPrice = (price: number, currency: string) => {
    switch (currency) {
        case "USD":
        case "usd":
        case "$": return "$" + price;
        case "GBP":
        case "gbp":
        case "£": return "£" + price;
        case "EUR":
        case "eur":
        case "€": return price + "€";
        default: return "£" + price
    }
}

function returnUsedCurrency(amount: any, currency: any) {
    if (!!currency) {
        switch (currency) {
            case "eur":
            case "€": return `${amount}€`
            case "£":
            case "gbp": return `£${amount}`
            case "$":
            default: return `$${amount}`
        }
    } else return `$${amount}`
}

function returnFractionFromDiscountPercentage(percentage: number) {
    return (100 - percentage) / 100
}

function returnYearlyPriceWithDiscount(value: number, discount: number) {
    let percentage = returnFractionFromDiscountPercentage(discount)
    return (value * 12) * percentage
}

export {
    roundToCent,
    returnUsedCurrency,
    returnFractionFromDiscountPercentage,
    returnYearlyPriceWithDiscount,
    formatPrice,
    capitalizeFirstLetter
}