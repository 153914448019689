


const vcardsFeatures = {
    keyFeatures: [
        "business_feature_1",
        "business_feature_2",
        "business_feature_3",
        "business_feature_4",
        "business_feature_5",
        "business_feature_6",
        "business_feature_7",
        "business_feature_8",
        "business_feature_9",
        "business_feature_10",
    ],
    enterpriseFeatures: [
        "enterprise_feature_2",
        "enterprise_feature_C_1",
        "enterprise_feature_C_2",
        "enterprise_feature_C_3",
        "enterprise_feature_C_4",
        "enterprise_feature_C_5",
        "enterprise_feature_C_6",
    ],
}

export {
    vcardsFeatures,
}