// BASE
import React from 'react';
import {BrowserRouter} from "react-router-dom";
// Routes
import UnitagRoutes from "./routes";
// Layout
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
// Stripe
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
// Contexts
import {DashboardUiProvider, ErrorBoundaryProvider, TourProvider, UserProvider} from "@context";
import {PermissionsProvider} from "@/Context/User/PermissionsProvider";

const {REACT_APP_STRIPE_KEY} = process.env;

function App() {
    const stripePromise = loadStripe(REACT_APP_STRIPE_KEY!);

    return (

            <Elements stripe={stripePromise}>
                <BrowserRouter>
                    <div className="App">
                        {/* New providers*/}
                        <ErrorBoundaryProvider>
                            <UserProvider>
                                <PermissionsProvider>
                                    <DashboardUiProvider>
                                        <TourProvider>
                                            <Navbar>
                                                <UnitagRoutes />
                                            </Navbar>
                                            <Footer/>
                                        </TourProvider>
                                    </DashboardUiProvider>
                                </PermissionsProvider>
                            </UserProvider>
                        </ErrorBoundaryProvider>

                    </div>
                </BrowserRouter>
            </Elements>

    );
}

export default App;
