// REACT
import React, {useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
// MATERIAL UI
import {
    Grid,
    Paper,
    Typography,
    Container,
    Divider,
    Stack, Skeleton, ButtonBase, IconButton, Box, Tooltip
} from '@mui/material';
import {
    School,
    GroupWork,
    Description,
    Settings,
    ArrowCircleLeft,
    ArrowCircleRight, Info
} from "@mui/icons-material";
// Custom assets & logos
import VCardProImage from "@assets/img/home/vcard-pro.png"
import PackagingImage from "@assets/img/home/vcard-product.png"
import OnboardBuyImage from "@assets/img/home/onboard-buy.svg"
import OnboardContactImage from "@assets/img/home/onboard-contact.svg"
// API
import {APIGet, APIPost} from "@api";
// types
import {GlobalNotification} from "@interfaces";
// translation
import {useTranslation} from "react-i18next";
// MIXPANEL
import mixpanel from 'mixpanel-browser';
// Unitag UI
import {Button} from '../../components';
// Context
import {useUser} from "@context"
// Components
import HomeStaticAlerts from "./components/HomeStaticAlerts";
// misc
import {standaloneSubscription} from "@/constants/plans_en";
import {uppercaseFirstLetter} from "@/functions/miscellanousFunctions";
import {useSnackbar} from "@hooks";
import {TourPopup} from "@components";
import {useTour} from "@/Context/Tour/TourProvider";
import {usePermissions} from "@/Context/User/PermissionsProvider";
import TrustpilotWidget from "@/widgets/Trustpilot";
import {HomeInvitationPopup} from "@/Views/Home/components";
// Icons
import {ReactComponent as QRCodesIcon} from "@assets/img/new-icons/qrcode.svg"
import {ReactComponent as VcardsIcon} from "@assets/img/new-icons/vcard.svg"
import {ReactComponent as AnalyticsIcon} from "@assets/img/new-icons/analytics.svg"
import {ReactComponent as AtlasIcon} from "@assets/img/new-icons/atlas.svg"
import {ReactComponent as PackagingIcon} from "@assets/img/new-icons/packaging.svg"
import {ReactComponent as CampaignsIcon} from "@assets/img/new-icons/campaigns.svg"
import {ReactComponent as WinelabelIcon} from "@assets/img/new-icons/winelabel.svg"
// Styles
import {useStyles} from "@/Context/Ui/DashboardUiProvider";

mixpanel.init('e54d191765b082621fa9ebf9da1867e2', {debug: false});

const {REACT_APP_ENV, REACT_APP_API_URL} = process.env;

export default function HomeDashboard() {

    const {t, i18n} = useTranslation(['home', 'common']);
    const {handleError, handleSuccess} = useSnackbar()
    const {theme} = useStyles()
    const history = useHistory()
    // Tour
    const {homeTour, setHomeTour, homeTourSteps} = useTour()
    // Permissions and user status
    const {hasAccess, accountType, isAccountTier, hasOrganisation, trueAccountType, isAccountRole} = usePermissions()
    // User data
    const {complexUser, credits, billingAlert, pendingInvitation} = useUser()

    const iid = window.localStorage.getItem("iid");
    const oid = window.localStorage.getItem("oid");

    const styles = {
        staticAlerts: { mt: 2 },
        appShortcutPaper: {
            p: 2,
            transition: "0.1s ease-in-out",
            borderRadius: 5,
            "&:hover": {
                borderColor: "primary.main",
                backgroundColor: "rgba(128,128,128,0.05)"
            }
        },
        shortcutButton: {
            width: "100%",
            borderRadius: 1,
            px: 2,
            py: 1,
            transition: "0.1s ease-in-out",
            justifyContent: "flex-start",
            border: "1px solid transparent",
            color: "black",
            "&:hover": {
                borderColor: "primary.main",
                backgroundColor: "rgba(128,128,128,0.05)"
            }
        },
        shortcutIcon: {
            fill: "grey",
            minWidth: "25px",
            mr: 1
        },
        featuredButton: {
            borderRadius: 5,
            "& .paper": {
                borderRadius: "inherit",
                transition: "0.1s ease-in-out",
                "&:hover": {
                    borderColor: "primary.main",
                    backgroundColor: "rgba(128,128,128,0.05)"
                }
            },
            "& img": {
                borderRadius: "20px 20px 0 0"
            }
        },
        appShortcutArrows: {
            left: {
                position: "absolute",
                left: "-25px",
                top: 44
            },
            right: {
                position: "absolute",
                right: "-25px",
                top: 44
            }
        }
    }

    // Maintenance text filled by global notifications system
    // const [maintenanceText, setMaintenanceText] = useState<string>('');
    // apps display page
    const [appDisplayPages, setAppDisplayPages] = useState<number>(1)
    // Credits
    const [displayedCredits, setDisplayedCredits] = useState<any>({
        qrcodes: undefined,
        vcardsPro: undefined,
        vcardsProduct: undefined,
        users: undefined,
        storage: undefined,
        webapps: undefined
    })

    // Initial calls on mount
    useEffect(() => {
        // Error not catched for it automatically displays on logout
        APIGet<GlobalNotification>(REACT_APP_API_URL + "/global_notification")
            .then((data: any) => {
                if (!data.parsedBody.textFR || !data.parsedBody.textFR) return
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Hydrate credits
    useEffect(() => {
        if (!!credits) {

            let _credits = {}

            for (const item of credits) {
                switch (item.service_name) {
                    case "users": _credits = {..._credits, users: item}; break;
                    case "vcard_product": _credits = {..._credits, vcardsProduct: item}; break;
                    case "vcards": _credits = {..._credits, vcardsPro: item}; break;
                    case "qrcodes": _credits = {..._credits, qrcodes: item}; break;
                    case "storage": _credits = {..._credits, storage: item}; break;
                    case "webapps": _credits = {..._credits, webapps: item}; break;
                }
            }

            setDisplayedCredits(_credits)
        }
    }, [credits])

    // Auto accept org invitation if redirected from mail link
    useEffect(() => {
        // If iid !== null then we have an invitation, and we need to accept it
        if (iid !== null && oid !== null) {
            APIPost<object>(REACT_APP_API_URL + "/org/" + oid + "/invite/" + iid + "/accept", {}).then(() => {
                window.localStorage.removeItem("oid")
                window.localStorage.removeItem("iid")
                handleSuccess(t("success_joining_organisation"))
                setTimeout(() => {
                    history.go(0)
                }, 1500)
            }).catch(() => handleError(t("error_accepting_invitation_from_mail")));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iid, oid])

    // Fix the missing tour thing when using Winelabel (and having analytics on the other page)
    useEffect(() => {
        if (homeTour === 7 && !!credits && hasAccess(["winelabel.access"])) {
            setAppDisplayPages(appDisplayPages + 1)
        } else if (homeTour === 8 && !!credits && hasAccess(["winelabel.access"])) {
            setAppDisplayPages(appDisplayPages - 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeTour])

    // Renders
    const renderLeftSection = () => {

        const renderAppShortcuts = () => {

            let iconProps = {
                fill: theme.palette.secondary.main,
                style: {
                    height: 35, width: 35
                }
            }

            let appsShortcuts = [
                { name: "qrcodes", icon: <QRCodesIcon {...iconProps} />, link: "/applications/qrcodes/list" },
                { name: "vcardpro", icon: <VcardsIcon {...iconProps} />, link: "/applications/vcardpro" },
                { name: "atlas", icon: <AtlasIcon {...iconProps} /> },
                { name: "packaging", icon: <PackagingIcon {...iconProps} /> },
                { name: "analytics", icon: <AnalyticsIcon {...iconProps} /> },
                { name: "campaigns", icon: <CampaignsIcon {...iconProps} /> },
            ];

            if (hasAccess("winelabel.access")) {
                appsShortcuts.unshift({ name: "Wine Label", icon: <WinelabelIcon {...iconProps} />, link: "/applications/winelabel/control_panel"})
            }

            return (
                <div style={{ position: "relative" }}>
                    <IconButton sx={styles.appShortcutArrows.left} size={"large"} onClick={() => setAppDisplayPages(appDisplayPages-1)} disabled={appDisplayPages === 1}>
                        <ArrowCircleLeft fontSize={"inherit"} />
                    </IconButton>
                    <Grid container spacing={2} justifyContent="center" alignItems="center" columns={10}>
                        {appsShortcuts.slice(5*(appDisplayPages-1), 5*appDisplayPages).map((app: any, index: number) => {
                            const {name, icon, link} = app;

                            const renderShortcutContent = (highlight: boolean) => {
                                return (
                                    <div>
                                        <Link to={link ? link : `/applications/${name}`}>
                                            <Paper variant={"outlined"} sx={{
                                                ...styles.appShortcutPaper,
                                                backgroundColor: highlight ? "rgba(175,201,40,0.4)" : "white",
                                                boxShadow: highlight ? "0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .3)" : "none",
                                                zIndex: highlight ? "3!important" : "0",
                                                position: highlight ? "relative" : "static"
                                            }}>
                                                <Stack direction={"column"} alignContent={"center"} justifyContent={"center"} textAlign={"center"} spacing={1}>
                                                    <Box>{icon}</Box>
                                                    <Typography
                                                        variant={"h5"}
                                                        align={"center"}
                                                        fontWeight={800}
                                                        textTransform={"uppercase"}
                                                        fontSize={17}
                                                        pt={1}
                                                    >
                                                        {t(name)}
                                                    </Typography>
                                                </Stack>
                                            </Paper>
                                        </Link>
                                    </div>
                                )
                            }

                            const isPartOfTour = () => {
                                switch (name) {
                                    case "qrcodes": return 3
                                    case "vcardpro": return 4
                                    case "atlas": return 5
                                    case "packaging": return 6
                                    case "analytics": return 7
                                    default: return undefined
                                }
                            }
                            return (
                                <Grid item xs={8} sm={6} md={4} lg={2} key={index}>
                                    {
                                        !!isPartOfTour() ?
                                            <TourPopup
                                                state={homeTour}
                                                setState={setHomeTour}
                                                steps={homeTourSteps}
                                                step={isPartOfTour()}
                                                tourApiIdentifier={"homepage"}
                                            >
                                                {renderShortcutContent(Boolean(isPartOfTour() === homeTour))}
                                            </TourPopup>
                                        : renderShortcutContent(false)
                                    }
                                </Grid>
                            )
                        })}
                    </Grid>
                    <IconButton sx={styles.appShortcutArrows.right} size={"large"} onClick={() => setAppDisplayPages(appDisplayPages+1)} disabled={appDisplayPages === 2}>
                        <ArrowCircleRight fontSize={"inherit"} />
                    </IconButton>
                </div>
            )
        }

        const renderFeatured = () => {

            const featured = [
                {
                    title: t("vcard_featured_title"),
                    text: t("vcard_featured_text"),
                    buttonText: t("get_started"),
                    image: VCardProImage,
                    link: "/alacarte",
                    disabled: isAccountRole(["member", "analyst"])
                },
                {
                    title: t("packaging_featured_title"),
                    text: t("packaging_featured_text"),
                    buttonText: t("Contact_us"),
                    image: PackagingImage,
                    link: "mailto:tech@unitag.io"
                }
            ]

            return (
                <Grid container justifyContent={"center"} spacing={2} mt={2}>
                    {
                        featured.map((feat: any, index: number) => (
                            <Grid item xs={12} md={6} key={index}>
                                <ButtonBase
                                    sx={styles.featuredButton}
                                    component={Link}
                                    to={{
                                        pathname: (feat.link.includes("mailto") || feat.disabled) ? "" : feat.link,
                                        state: feat.link.includes("alacarte") ?
                                            {
                                                fromPlan: isAccountTier(["standard", "gold", "platinum", "standalone"]) ? trueAccountType : "standalone",
                                                topUp: isAccountTier(["standard", "gold", "platinum", "standalone"])
                                            }
                                            : null
                                    }}
                                    onClick={(e: any) => {
                                        if (feat.link.includes("mailto")) {
                                            window.location.href = feat.link
                                            e.preventDefault();
                                        }
                                    }}>
                                    <Paper variant={"outlined"} className={"paper"}>
                                        <Stack direction={"column"}>
                                            <img
                                                alt={feat.title}
                                                src={feat.image}
                                                style={{ width: "100%", height: "auto", maxHeight: 500 }}
                                            />
                                            <div style={{ marginTop: 16, minHeight: 60 }}>
                                                <Typography align={"center"} variant={"body2"} fontWeight={700}>{feat.title}</Typography>
                                                <Typography align={"center"} variant={"body2"}>{feat.text}</Typography>
                                            </div>
                                            <div style={{ marginBottom: 16, textAlign: "center" }}>
                                                <Button secondary outlined small>
                                                    {feat.buttonText}
                                                </Button>
                                            </div>
                                        </Stack>
                                    </Paper>
                                </ButtonBase>
                            </Grid>
                        ))
                    }
                </Grid>
            )
        }

        return (
            <>
                <Grid item xs={12} sm={8} lg={7}>
                    {renderAppShortcuts()}
                    <HomeStaticAlerts isSandbox={REACT_APP_ENV === "sandbox"}{...{billingAlert, i18n, styles, t}} />
                    <HomeInvitationPopup {...{pendingInvitation}} />
                    {renderFeatured()}
                </Grid>
            </>
        )
    }

    const renderRightSection = () => {

        const renderAccountSummary = () => {

            const orgWidgetStyle = {
                width: 20, height: 20, mb: "-2px",
                color: trueAccountType + ".main"
            }

            return (
                <>
                    <Typography variant={"h5"} component={"p"} align={"center"} color={"darkgrey"}>
                        Plan
                    </Typography>
                    {!complexUser ?
                        <Skeleton variant={"text"} animation={"wave"} sx={{ fontSize: "24px" }}  />
                    :
                        <Typography variant={"h5"} component={"p"} align={"center"} color={`${trueAccountType}.main`}>
                            {uppercaseFirstLetter(t(trueAccountType))}
                            {
                                (hasOrganisation && !isAccountRole(["owner"])) &&
                                <>
                                    &nbsp;
                                    <Tooltip title={t("plan_from_org")} placement={"top"} arrow>
                                        <GroupWork sx={orgWidgetStyle} />
                                    </Tooltip>
                                </>
                            }
                        </Typography>
                    }
                </>
            )
        }

        const renderOnboardingOptions = () => {

            const onboardingOptions = [
                {
                    label: t("onboard_upgrade"),
                    image: OnboardBuyImage,
                    buttonText: t("Upgrade"),
                    link: "/plans"
                },
                {
                    label: t("onboard_contact"),
                    image: OnboardContactImage,
                    buttonText: t("Contact_us"),
                    link: "mailto:tech@unitag.io"
                }
            ]

            return (
                <Stack direction={"column"} spacing={2} mt={2}>
                    {
                        onboardingOptions.map((option: any, index: number) => (
                            <div style={{textAlign: "center", margin: "auto"}} key={index}>
                                <img src={option.image} height={100} width={100} alt={option.label} />
                                <Typography variant={"body2"} align={"center"}>
                                    {option.label}
                                </Typography>
                                <Button
                                    secondary small
                                    component={Link}
                                    to={option.link.includes("mailto") ? "#" : option.link}
                                    onClick={(e: any) => {
                                        if (option.link.includes("mailto")) {
                                            window.location.href = option.link
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {option.buttonText}
                                </Button>
                            </div>
                        ))
                    }
                </Stack>
            )
        }

        const renderCreditsSummary = () => {

            if (isAccountRole(["member", "analyst"])) return <></>

            const creditsToDisplay = [
                {
                    label: "QR Codes",
                    value: displayedCredits.qrcodes,
                    display: true,
                    returnText: (used: number, left: any) => {
                        return `${used}/${(typeof left === "number") ? used + left : left}`
                    }
                },
                {
                    label: "vCards Pro",
                    value: displayedCredits.vcardsPro,
                    display: true,
                    returnText: (used: number, left: any) => {
                        return `${used} ${t("created")}/${(typeof left === "number") ? used + left : left}`
                    }
                },
                {
                    label: t("storage"),
                    value: displayedCredits.storage,
                    display: true,
                    returnText: (used: number, left: any) => {
                        if (used === 0) return `0% ${t("used")}`
                        let percentage = Math.round((used/(used+left))*100)
                        return `${Number(percentage)}% ${t("used")}`
                    }
                },
                {
                    label: t("Usage_extra_users"),
                    value: displayedCredits.users,
                    display: isAccountTier(["standard", "gold", "platinum"]),
                    returnText: (used: number, left: any) => {
                        return `${used} ${t("active")}/${(typeof left === "number") ? used + left : left}`
                    }
                }
            ]

            return (
                <>
                    {
                        creditsToDisplay.map((cred: any, index: any) => {
                            if (cred.value === undefined || !cred.display) return <div key={index}></div>

                            let used = cred.value.credits_used
                            let left = cred.value.credits_left <= 999999 ? cred.value.credits_left : t("unlimited")

                            return (
                                <Grid item xs={12} pt={2} key={index}>
                                    <Typography variant={"body1"} component={"p"} align={"center"} color={"darkgrey"}>
                                        {cred.label}
                                    </Typography>
                                    <Typography variant={"body1"} component={"p"} align={"center"} fontWeight={700}>
                                        {cred.returnText(used, left)}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </>
            )
        }

        const renderUpgrades = () => {

            if (isAccountRole(["member", "analyst"])) return <></>

            const returnUpgradePath = () => {
                return {
                    pathname: "/alacarte",
                    state: {
                        fromPlan: isAccountTier(["free"]) ? standaloneSubscription : trueAccountType,
                        topUp: !isAccountTier(["free"])
                    }
                }
            }

            return (
                <Grid container rowSpacing={1} mt={2}>
                    <Grid item xs={12}>
                        <Button fullWidth primary dense component={Link} to={"/plans"}>
                            {t("Upgrade")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth small secondary dense outlined component={Link} to={returnUpgradePath()}>
                            {t("Credits_add")}
                        </Button>
                    </Grid>
                </Grid>
            )
        }

        const renderShortcuts = () => {

            const renderShortcutElement = (shortcut: any) => {

                if (!shortcut.display) return <></>

                return (
                    <div>
                        { shortcut.divider && <Divider sx={{my: 2}}/> }
                        <Link
                            to={{ pathname: shortcut.link, search: shortcut.params ? shortcut.params : "" }}
                            target={shortcut.link.includes("http") ? "_blank" : "_self"}
                        >
                            <ButtonBase sx={styles.shortcutButton}>
                                <Stack direction={'row'} spacing={1}>
                                    {shortcut.icon}
                                    <Typography variant={"body1"}>
                                        {shortcut.label}
                                    </Typography>
                                </Stack>
                            </ButtonBase>
                        </Link>
                    </div>
                )

            }

            const shortcuts = [
                {
                    label: t("Account_settings"),
                    icon: <Settings sx={styles.shortcutIcon}/>,
                    link: "/settings",
                    display: true,
                    divider: true
                },
                {
                    label: t("Organizations"),
                    icon: <GroupWork sx={styles.shortcutIcon}/>,
                    link: "/settings",
                    params: "?organisations",
                    display: (isAccountRole(["admin", "owner"]) && isAccountTier(["gold", "platinum"]))
                },
                {
                    label: t("Documentation"),
                    icon: <Description sx={styles.shortcutIcon}/>,
                    link: "https://unitag.io/documentation",
                    display: true,
                    divider: true
                },
                {
                    label: t("elearning"),
                    icon: <School sx={styles.shortcutIcon}/>,
                    link: "/e-learning",
                    display: isAccountTier(["platinum"]),
                    divider: true
                },

            ]

            return (
                <>
                    {shortcuts.map((shortcut: any, index: number) => (
                        <div key={index}>
                            {renderShortcutElement(shortcut)}
                        </div>
                    ))}
                </>
            )
        }

        return (
            <Grid item xs={12} sm={4} lg={3}>
                <TourPopup
                    state={homeTour}
                    setState={setHomeTour}
                    step={1}
                    steps={homeTourSteps}
                    tourApiIdentifier={"homepage"}
                >
                    <Paper variant={"outlined"} sx={{
                        p: 2, borderRadius: 5,
                        backgroundColor: homeTour === 1 ? "rgba(175,201,40,0.4)" : "white",
                        boxShadow: homeTour === 1 ? "0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .3)" : "none",
                        zIndex: homeTour === 1 ? "3!important" : "0",
                        position: homeTour === 1 ? "relative" : "static"
                    }}>
                        <Typography variant={"h5"} align={"center"}>
                            {t("common:Your_account")}
                        </Typography>
                        <Divider sx={{ mx: 4, pt: 1, mb: 3 }} />

                        {
                            !accountType || !complexUser ? <div style={{ marginTop: 16 }}><Skeleton animation={"wave"} height={250} variant={"rectangular"} sx={{ borderRadius: 5 }} /></div>
                            : isAccountTier(["free"]) && !hasOrganisation ? renderOnboardingOptions()
                            : <>{renderAccountSummary()}{renderCreditsSummary()}{renderUpgrades()}</>
                        }
                        {renderShortcuts()}
                    </Paper>
                </TourPopup>
            </Grid>
        )
    }

    // The sole purpose of this function is to create a ghost element for the Tour to point on when showing the Support element
    const renderBottomGhostSupport = () => {
        return (
            <Box sx={{position: "fixed", bottom: 20, right: 45, height: "50px", width: "100px"}}>
                <TourPopup state={homeTour} setState={setHomeTour} steps={homeTourSteps} step={8} tourApiIdentifier={"homepage"}>
                    <Box sx={{ position: "relative", height: "1px", mt: "10px",
                        boxShadow: homeTour === 8 ? "0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .3)" : "none",
                    }}></Box>
                </TourPopup>
            </Box>
        )
    }

    return (
        <>
            <Container sx={{pb: 5, mt: "40px"}} maxWidth={"xl"}>
                <Grid container spacing={2} justifyContent="center">
                    {/* TITLE */}
                    <Grid item xs={12} md={7}>
                        <Stack direction={"row"} justifyContent={"flex-start"}>
                            <Typography variant={"h1"}>
                                Unitag console
                            </Typography>
                            <Box sx={{ ml: 1, mt: "12px"}}>
                                <TourPopup
                                    step={9}
                                    steps={homeTourSteps}
                                    state={homeTour}
                                    setState={setHomeTour}
                                    tourApiIdentifier={"homepage"}
                                >
                                    <Box sx={{
                                        color: homeTour === 9 ? "rgba(175,201,40,0.4)" : "transparent",
                                        boxShadow: homeTour === 9 ? "0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .3)" : "none",
                                        zIndex: 1000,
                                        position: "relative!important",
                                        borderRadius: "50%"
                                    }}>
                                        <Tooltip title={t("start_tour")} placement={"right"} arrow>
                                            <div>
                                                <IconButton
                                                    disabled={!!homeTour}
                                                    color={"primary"}
                                                    size={"small"}
                                                    onClick={() => setHomeTour(1)}
                                                >
                                                    <Info sx={{ height: 30, width: 30 }} />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Box>
                                </TourPopup>
                            </Box>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={3} textAlign={"right"}>
                        <TrustpilotWidget />
                    </Grid>

                    {/* LEFT SECTION */}
                    {renderLeftSection()}

                    {/* Right Section */}
                    {renderRightSection()}
                </Grid>
            </Container>

            {/*  Render ghost support for tour  */}
            {renderBottomGhostSupport()}

        </>
    );
}
